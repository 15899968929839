import React, { useRef, useEffect, useState } from 'react'
import { useStyles } from './styles'
import {
  Viewer,
  SpecialZoomLevel,
  Icon,
  MinimalButton,
  Position,
  Tooltip,
  ScrollMode,
  PageLayout
} from '@react-pdf-viewer/core'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import { toolbarPlugin } from '@react-pdf-viewer/toolbar'
import AIGeneratePlugin from '../../sections/AnalyticsPDFView/AIGeneratePlugin'
import { highlightPlugin } from './highlight'
import { ReactComponent as Robot } from '../../assets/images/robot.svg'
import SvgIcon from '@mui/material/SvgIcon'
import CreateLibraryMeta from '../../containers/CreateLibraryMeta'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import BookIcon from '@mui/icons-material/BookOutlined'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DoneIcon from '@mui/icons-material/Done'
import _, { isEmpty, set } from 'lodash'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'
import { PDFDocument } from 'pdf-lib'
import { IconButton, Tooltip as MUIToolTip } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'
import CircularProgress from '@mui/material/CircularProgress'
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import {
  fullScreenPlugin,
  RenderEnterFullScreenProps
} from '@react-pdf-viewer/full-screen'
import {
  ThumbnailDirection,
  thumbnailPlugin
} from '@react-pdf-viewer/thumbnail'
import Error from '../../components/Error'
import { Container, Section, SectionFixed } from '../Container'
import disableScrollPlugin from './disableScrollPlugin'
import SearchOffIcon from '@mui/icons-material/SearchOff'
import { hidePageData } from '../../store/api'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { toast } from 'react-toastify'
import { checkUserRoleViewer } from '../../utils/User'
import { addContentToChat } from '../../store/Chat/Actions'
import { useDispatch } from 'react-redux'
import ChatIcon from '@mui/icons-material/Chat'
import { searchPlugin } from '@react-pdf-viewer/search'
import { SparkIcon2 } from '../Icons/Icons'
import PdfViewerContextMenu from './pdf-viewer-context-menu'

const App = (props) => {
  let {
    url,
    pageNumber,
    searchKeyword = '',
    enableHighlightPlugins = false,
    enableAddtoChat = false,
    enableAIPlugin = false,
    enableAddToNarratives = false,
    enableCopy = false,
    PDFData,
    enablePageThumbnail = false,
    PDFOrgUrl = '',
    setCurrentPageNumber = () => {}
  } = props
  pageNumber = pageNumber - 1 >= 0 ? pageNumber - 1 : pageNumber
  const classes = useStyles()
  const {
    webviewer,
    pptThumbnails,
    pptViewerBottomLine,
    pptViewerNavLine,
    pptViewerNavButton,
    pptViewerFullScreenButton
  } = classes
  const [showLibraryModel, setShowLibraryModel] = useState(false)
  const [metaData, setMetaData] = useState({})
  const [isCopied, setIsCopied] = useState(false)
  const toolbarPluginInstance = toolbarPlugin()

  const searchPluginInstance = searchPlugin({
    keyword: searchKeyword.split(' ')
  })

  const dispatch = useDispatch()
  const { setTargetPages } = searchPluginInstance

  const [pagesHiddenArray, setPagesHiddenArray] = useState([])

  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  const isUserViewer = checkUserRoleViewer()

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const { renderDefaultToolbar } = toolbarPluginInstance
  const [aiPlugin, setAIPlugin] = useState(null)

  const transform = (slot) => {
    return {
      ...slot,
      Open: () => <></>,
      OpenMenuItem: () => <></>,
      Print: () => <></>,
      PrintMenuItem: () => <></>,
      Download: () => <></>,
      DownloadMenuItem: () => <></>,
      SwitchScrollMode: () => <></>,
      SwitchScrollModeMenuItem: () => <></>
    }
  }

  const handleNewClick = (props) => {
    trackEvent(
      mixpanelEvents.DASHBOARD_RESULT_PROPOSAL_CREATE_KNOWLEDGE,
      'SUCCESS',
      {},
      {
        search_keyword: searchKeyword,
        proposal_id: PDFData?.id,
        proposal_filename: PDFData?.name
      }
    )
    setMetaData({
      src: 'proposal',
      proposal_id: PDFData?.id,
      proposal_filename: PDFData?.name,
      pageIndex: props.props.selectionRegion.pageIndex + 1,
      bounds: {
        height: props.props.selectionRegion.height,
        left: props.props.selectionRegion.left,
        top: props.props.selectionRegion.top,
        width: props.props.selectionRegion.width
      },
      selectedText: props.props.selectionData.selectedText
    })
  }

  const handleCopyClick = (props) => {
    setIsCopied(true)
    trackEvent(
      mixpanelEvents.DASHBOARD_RESULT_PROPOSAL_TEXT_COPIED,
      'SUCCESS',
      {},
      {
        search_keyword: searchKeyword,
        proposal_id: PDFData?.id,
        proposal_filename: PDFData?.name
      }
    )
    const selectedText = props.props.selectionData.selectedText
    const pattern = /\. {2}/g
    const replacement = '.\n'
    let replacedText = selectedText.replace(pattern, replacement)

    const spacePattern = / {2}/g
    const spaceReplacement = ' '
    replacedText = replacedText.replace(spacePattern, spaceReplacement)
    navigator.clipboard.writeText(replacedText)
    setTimeout(() => {
      setIsCopied(false)
    }, 1500)
  }

  useEffect(() => {
    if (!isEmpty(metaData)) {
      setShowLibraryModel(true)
    }
  }, [metaData])
  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'c') {
        event.preventDefault()
        try {
          const selectedText = window.getSelection().toString()

          const pattern = /\. {2}/g
          const replacement = '.\n'
          let replacedText = selectedText.replace(pattern, replacement)

          const spacePattern = / {2}/g
          const spaceReplacement = ' '
          replacedText = replacedText.replace(spacePattern, spaceReplacement)

          const newlinePattern = /(?<!\. ?)\n/g
          replacedText = replacedText.replace(newlinePattern, ' ')
          navigator.clipboard.writeText(replacedText)
        } catch (e) {
          console.error(e)
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  const highlightItems = [
    {
      id: 'ai-generate',
      label: 'AI Generate',
      icon: <SparkIcon2 className="size-4" strokeWidth="1.7" />,
      onClick: (e, props) => {
        setAIPlugin({ e, props })
        trackEvent(
          mixpanelEvents.DASHBOARD_RESULT_PROPOSAL_AI_GENERATE_USED,
          'SUCCESS',
          {},
          {
            search_keyword: searchKeyword,
            proposal_id: PDFData?.id,
            proposal_filename: PDFData?.name
          }
        )
      },
      show: enableAIPlugin
    },
    {
      id: 'add-to-chat',
      label: 'Add to Chat',
      icon: <SparkIcon2 className="size-4" strokeWidth="1.7" />,
      onClick: (e, props) => {
        console.log('props', props)
        props?.toggle()
        const selectedText = props.selectionData.selectedText
        const pattern = /\. {2}/g
        const replacement = '.\n'
        let replacedText = selectedText.replace(pattern, replacement)
        const spacePattern = / {2}/g
        const spaceReplacement = ' '
        replacedText = replacedText.replace(spacePattern, spaceReplacement)
        dispatch(addContentToChat(replacedText))
      },
      show: enableAddtoChat
    },
    {
      id: 'add-to-narratives',
      label: 'Create Knowledge Content',
      icon: <BookIcon className="size-4" />,
      onClick: (e, props) => {
        handleNewClick({ e, props })
        props?.toggle()
      },
      show: enableAddToNarratives
    },
    {
      id: 'copy',
      label: isCopied ? 'Copied' : 'Copy',
      icon: isCopied ? (
        <DoneIcon className="size-4" />
      ) : (
        <ContentCopyIcon className="size-4" />
      ),
      onClick: (e, props) => {
        e.preventDefault()
        handleCopyClick({ e, props })
      },
      show: enableCopy
    }
  ]

  const renderHighlightTarget = (props) => {
    // Render absolute elements over the highlighted areas
    const renderHighlightOverlays = () => {
      // Clear the selection and render custom highlights
      if (document.getSelection) {
        document.getSelection()?.removeAllRanges()
      }

      const areas =
        props.highlightAreas && props.highlightAreas.length > 0
          ? props.highlightAreas
          : []

      if (areas.length === 0) return null

      return areas.map((area, index) => (
        <div
          key={`highlight-overlay-${index}`}
          className="absolute pointer-events-none z-[1000] bg-violet-300/70 mix-blend-multiply"
          style={{
            left: `${area.left}%`,
            top: `${area.top}%`,
            width: `${area.width}%`,
            height: `${area.height}%`
          }}
        />
      ))
    }

    return (
      <>
        {renderHighlightOverlays()}
        <PdfViewerContextMenu
          style={{
            zIndex: 1500
          }}
          position={{
            x: `${props.selectionRegion.left}%`,
            y: `${props.selectionRegion.top + props.selectionRegion.height}%`
          }}
          onClose={props?.cancel}
          items={highlightItems.filter((item) => item.show)}
          textSelectProps={props}
        />
      </>
    )
  }

  const highlightPluginInstance = highlightPlugin({
    renderHighlightTarget
  })

  const renderToolbar = (Toolbar) => (
    <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
  )

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [],
    renderToolbar
  })

  const [selectedPages, setSelectedPages] = useState([])
  const [allSelected, setAllSelected] = useState(false)
  const [someSelected, setSomeSelected] = useState(false)
  const [numberofPagesSelected, setNumberofPagesSelected] = useState(0)
  const [isDownloading, setIsDownloading] = useState(false)
  const [isPageDisabling, setIsPageDisabling] = useState(false)
  const [isPageEnabling, setIsPageEnabling] = useState(false)

  const handlePagesHidden = (pages_hidden) => {
    const pagesHidden = new Array(pages_hidden.length).fill(false)
    for (let i = 0; i < pages_hidden.length; i++) {
      pagesHidden[pages_hidden[i] - 1] = true
    }
    setPagesHiddenArray(pagesHidden)
  }

  useEffect(() => {
    if (url) {
      const pages_hidden = PDFData?.pages_hidden_from_search?.pages || []
      handlePagesHidden(pages_hidden)
    }
  }, [PDFData])

  const handleDocumentLoad = (e) => {
    setSelectedPages(Array(e.doc.numPages).fill(false))

    setTargetPages((targetPage) => targetPage.pageIndex === pageNumber)
  }

  const selectAllPages = () => {
    setSelectedPages((selectedPages) => Array(selectedPages.length).fill(true))
    setAllSelected(true)
    setSomeSelected(false)
    setNumberofPagesSelected(selectedPages.length)
  }

  const deselectAllPages = () => {
    setSelectedPages((selectedPages) => Array(selectedPages.length).fill(false))
    setAllSelected(false)
    setSomeSelected(false)
    setNumberofPagesSelected(0)
  }

  const handleChoosePage = (e, pageIndex) => {
    const isSelected = e.target.checked
    selectedPages[pageIndex] = isSelected
    setSelectedPages([...selectedPages])
    let checkSomeSelected = false
    let checkAllSelected = true
    for (const page of selectedPages) {
      if (page) {
        checkSomeSelected = true
      } else {
        checkAllSelected = false
      }
    }
    setAllSelected(checkAllSelected)
    setSomeSelected(checkSomeSelected)
    setNumberofPagesSelected(selectedPages.filter((page) => page).length)
  }

  const renderThumbnailItem = (props) => (
    <div
      key={props.pageIndex}
      className="custom-thumbnail-item"
      data-testid={`thumbnail-${props.pageIndex}`}
      style={{
        backgroundColor:
          props.pageIndex === props.currentPage
            ? 'var(--grey-200)'
            : 'var(--grey-100)',
        cursor: 'pointer',
        padding: '12px 0',
        width: '100%',
        maxWidth: '150px',
        borderRadius: '6px'
      }}
    >
      <div
        style={{
          marginBottom: '0.5rem',
          position: 'relative',
          cursor: 'pointer',
          display: 'inline-block',
          width: '100%',
          margin: 'auto'
        }}
        onClick={props.onJumpToPage}
      >
        <div
          style={{
            position: 'absolute',
            zIndex: 1,
            width: '100%',
            height: '100%',
            backgroundColor: pagesHiddenArray[props.pageIndex]
              ? 'rgba(0, 0, 0, 0.15)'
              : 'rgba(0, 0, 0, 0)',
            margin: 'auto'
          }}
        >
          <span
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)'
            }}
          >
            {pagesHiddenArray[props.pageIndex] && (
              <Tooltip
                position={Position.BottomCenter}
                target={
                  <IconButton disableRipple>
                    <VisibilityOffIcon />
                  </IconButton>
                }
                content={() => (
                  <span style={{ fontSize: '10px', whiteSpace: 'nowrap' }}>
                    hidden from search
                  </span>
                )}
                offset={{ left: 0, top: 8 }}
              />
            )}
          </span>
        </div>
        {props.renderPageThumbnail}
      </div>
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          margin: '0 auto',
          width: '100px'
        }}
      >
        <input
          type="checkbox"
          checked={selectedPages[props.pageIndex] || false}
          onChange={(e) => handleChoosePage(e, props.pageIndex)}
        />
        <div style={{ marginLeft: 'auto' }} className="text-xxs">
          Page {props.renderPageLabel}
        </div>
      </div>
    </div>
  )

  const thumbnailPluginInstance = thumbnailPlugin()
  const { Thumbnails } = thumbnailPluginInstance

  const handleDownloadCustomPdf = async () => {
    setIsDownloading(true)
    const pdfUrl = PDFOrgUrl !== '' ? PDFOrgUrl : url
    const arrayBuffer = await fetch(pdfUrl).then((res) => res.arrayBuffer())
    const pdfDoc = await PDFDocument.load(arrayBuffer)
    const newPdf = await PDFDocument.create()

    const pagesToBeCopied = []
    for (const [idx, page] of selectedPages.entries()) {
      if (page) {
        pagesToBeCopied.push(idx)
      }
    }
    const copiedPages = await newPdf.copyPages(pdfDoc, pagesToBeCopied)

    for (const copiedPage of copiedPages) {
      newPdf.addPage(copiedPage)
    }

    let pdfName = PDFData?.name?.split('.pdf')?.[0] || ''

    const firstIndex = selectedPages.indexOf(true)
    const lastIndex = selectedPages.lastIndexOf(true)

    pdfName = `${pdfName}_pages_${firstIndex + 1}_${lastIndex + 1}`

    const pdfBytes = await newPdf.save()
    const blob = new Blob([pdfBytes], { type: 'application/pdf' })

    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = pdfName
    link.click()
    setIsDownloading(false)
  }

  const handleDisablePagesFromSearch = async (action = '') => {
    if (action === 'hide') setIsPageDisabling(true)
    else setIsPageEnabling(true)
    const data = {
      proposal_id: PDFData?.id,
      page_number: selectedPages
        .map((page, index) => (page ? index + 1 : null))
        .filter((page) => page !== null),
      domain_id: PDFData?.created_for_domain,
      action
    }

    const response = await hidePageData(data)
    if (action === 'hide') setIsPageDisabling(false)
    else setIsPageEnabling(false)
    if (response.status === 200) {
      if (action === 'hide') {
        const dupPagesHiddenArray = _.cloneDeep(pagesHiddenArray)
        for (const [index, page] of selectedPages.entries()) {
          if (page) {
            dupPagesHiddenArray[index] = true
          }
        }
        setPagesHiddenArray(dupPagesHiddenArray)
      } else {
        const dupPagesHiddenArray = _.cloneDeep(pagesHiddenArray)
        for (const [index, page] of selectedPages.entries()) {
          if (page) {
            dupPagesHiddenArray[index] = false
          }
        }
        setPagesHiddenArray(dupPagesHiddenArray)
      }
      setSelectedPages([])
      toast.success('Page visibility updated successfully')
    } else {
      toast.error('Failed to update page visibility')
    }
  }

  const { file_type = 'pdf' } = PDFData || {}

  const pageNavigationPluginInstance = pageNavigationPlugin()
  const { GoToNextPage, GoToPreviousPage } = pageNavigationPluginInstance
  const [pageNo, setPageNo] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [pageHeight, setPageHeight] = useState(400)

  const handlePageHeightChange = (e) => {
    setPageHeight(e)
    return true
  }

  const handlePageChange = (e) => {
    setPageNo(e.currentPage)
    setTotalPages(e.doc._pdfInfo.numPages)
    setCurrentPageNumber(e.currentPage)
  }

  const fullScreenPluginInstance = fullScreenPlugin()
  const { EnterFullScreen } = fullScreenPluginInstance

  const disableScrollPluginInstance = disableScrollPlugin()

  const pageLayout = {
    buildPageStyles: () => ({
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center'
    }),
    transformSize: ({ size }) => ({
      height: handlePageHeightChange(size.height + 30) && size.height - 10,
      // width: size.width,
      width: screenWidth > 768 ? screenWidth - 240 : screenWidth - 50
    })
  }

  return !url ? (
    <Error
      errorMessage={
        'File not found. It may still be processing. Please try again later.'
      }
      refresh={false}
      errorLogo={false}
    />
  ) : enableHighlightPlugins ? (
    <div className={webviewer}>
      {url && (
        <>
          {
            // file_type === 'ppt' ?
            //   <Container>
            //     <Section overFlow>
            //       <Viewer
            //         fileUrl={url}
            //         plugins={
            //           [
            //             pageNavigationPluginInstance,
            //             fullScreenPluginInstance,
            //             thumbnailPluginInstance,
            //             highlightPluginInstance,
            //             disableScrollPluginInstance
            //           ]
            //         }
            //         defaultScale={SpecialZoomLevel.PageFit}
            //         scrollMode={ScrollMode.Horizontal}
            //         onPageChange={handlePageChange}
            //         pageLayout={pageLayout}
            //         initialPage={pageNumber}
            //       />
            //     </Section>
            //     <SectionFixed>
            //       <div className={pptThumbnails}>
            //         {searchKeyword === ''
            //           ? <Thumbnails thumbnailDirection={ThumbnailDirection.Horizontal} />
            //           : null
            //         }
            //       </div>
            //       <div className={pptViewerBottomLine}>
            //         <div className={pptViewerNavLine}>
            //           <div className={pptViewerNavButton}>
            //             <GoToPreviousPage>
            //               {(props) => (
            //                 <Tooltip
            //                   position={Position.BottomCenter}
            //                   target={
            //                     <IconButton disableRipple onClick={props.onClick}>
            //                       <ChevronLeftIcon />
            //                     </IconButton>
            //                   }
            //                   content={() => 'Previous page'}
            //                   offset={{ left: 0, top: 8 }}
            //                 />
            //               )}
            //             </GoToPreviousPage>
            //           </div>
            //           <p style={{
            //             color: 'black',
            //             margin: '0 10px',
            //           }}>{pageNo + 1} of {totalPages}</p>
            //           <div className={pptViewerNavButton}>
            //             <GoToNextPage>
            //               {(props) => (
            //                 <Tooltip
            //                   position={Position.BottomCenter}
            //                   target={
            //                     <IconButton disableRipple onClick={props.onClick}>
            //                       <ChevronRightIcon />
            //                     </IconButton>
            //                   }
            //                   content={() => 'Next page'}
            //                   offset={{ left: 0, top: 8 }}
            //                 />
            //               )}
            //             </GoToNextPage>
            //           </div>
            //         </div>
            //         <div className={pptViewerFullScreenButton}>
            //           <EnterFullScreen>
            //             {(props) => (
            //               <Tooltip
            //                 position={Position.BottomCenter}
            //                 target={
            //                   <IconButton disableRipple onClick={props.onClick}>
            //                     <FullscreenIcon />
            //                   </IconButton>
            //                 }
            //                 content={() => 'Full Screen'}
            //                 offset={{ left: 0, top: 8 }}
            //               />
            //             )}
            //           </EnterFullScreen>
            //         </div>
            //       </div>
            //     </SectionFixed>
            //   </Container>
            //   :
            <div
              style={{
                display: 'flex',
                height: '98%'
              }}
            >
              {enablePageThumbnail && (
                <div
                  style={{
                    width: '20%',
                    borderRight: '1px solid var(--grey-200)'
                  }}
                >
                  <div
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      margin: '0 auto',
                      textAlign: 'center',
                      height: '2.5rem',
                      backgroundColor: 'var(--grey-100)'
                    }}
                  >
                    <div
                      style={{
                        margin: 'auto'
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={allSelected}
                        onChange={(e) => {
                          if (e.target.checked && someSelected === false) {
                            selectAllPages()
                          } else {
                            deselectAllPages()
                          }
                        }}
                        ref={(input) => {
                          if (input) {
                            input.indeterminate = someSelected === true
                          }
                        }}
                      />
                      {numberofPagesSelected > 0 && (
                        <span style={{ marginLeft: '5px' }}>
                          {numberofPagesSelected} selected
                        </span>
                      )}
                    </div>

                    <div
                      style={{
                        margin: 'auto'
                      }}
                    >
                      {isDownloading ? (
                        <CircularProgress size={20} />
                      ) : (
                        <MUIToolTip title="Download Selected Pages">
                          <IconButton
                            onClick={handleDownloadCustomPdf}
                            disabled={numberofPagesSelected === 0}
                          >
                            <DownloadIcon sx={{ fontSize: '20px' }} />
                          </IconButton>
                        </MUIToolTip>
                      )}
                    </div>

                    <div
                      style={{
                        margin: 'auto'
                      }}
                    >
                      {isPageDisabling ? (
                        <CircularProgress size={20} />
                      ) : (
                        <MUIToolTip title="Hide these pages from content search">
                          <IconButton
                            onClick={() => {
                              handleDisablePagesFromSearch('hide')
                            }}
                            disabled={
                              numberofPagesSelected === 0 || isUserViewer
                            }
                          >
                            <VisibilityOffIcon sx={{ fontSize: '20px' }} />
                          </IconButton>
                        </MUIToolTip>
                      )}
                    </div>
                    <div
                      style={{
                        margin: 'auto'
                      }}
                    >
                      {isPageEnabling ? (
                        <CircularProgress size={20} />
                      ) : (
                        <MUIToolTip title="Show these pages in content search">
                          <IconButton
                            onClick={() => {
                              handleDisablePagesFromSearch('show')
                            }}
                            disabled={
                              numberofPagesSelected === 0 || isUserViewer
                            }
                          >
                            <VisibilityIcon sx={{ fontSize: '20px' }} />
                          </IconButton>
                        </MUIToolTip>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      height: '95%',
                      overflow: 'hidden',
                      borderTop: '1px solid var(--grey-300)'
                    }}
                  >
                    <Thumbnails renderThumbnailItem={renderThumbnailItem} />
                  </div>
                </div>
              )}
              <div
                style={{
                  flex: 1,
                  overflow: 'hidden'
                }}
              >
                <Viewer
                  onDocumentLoad={handleDocumentLoad}
                  plugins={[
                    searchPluginInstance,
                    defaultLayoutPluginInstance,
                    highlightPluginInstance,
                    toolbarPluginInstance,
                    thumbnailPluginInstance
                  ]}
                  fileUrl={url}
                  initialPage={pageNumber}
                  defaultScale={1}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          }
        </>
      )}
      {enableAIPlugin && aiPlugin && (
        <AIGeneratePlugin
          aiPlugin={aiPlugin}
          setAIPlugin={setAIPlugin}
          {...props}
        />
      )}{' '}
      {enableAddToNarratives && (
        <Modal
          fullWidth={true}
          sx={{
            padding: '100px',
            zIndex: 1300
          }}
          disableEscapeKeyDown
          open={showLibraryModel}
          onClose={() => setShowLibraryModel(false)}
        >
          <Box
            className="libContainer"
            sx={{
              width: '100%',
              height: '100%',
              background: 'white'
            }}
          >
            <CreateLibraryMeta
              onClose={() => setShowLibraryModel(false)}
              openEditor={true}
              htmlString={metaData?.selectedText}
              metaData={metaData}
            />
          </Box>
        </Modal>
      )}
    </div>
  ) : (
    <div className={webviewer}>
      {url && (
        <Viewer
          plugins={[
            searchPluginInstance,
            defaultLayoutPluginInstance,
            toolbarPluginInstance
          ]}
          fileUrl={url}
          initialPage={pageNumber}
          defaultScale={SpecialZoomLevel.PageFit}
          onPageChange={handlePageChange}
        />
      )}
    </div>
  )
}

export default App
