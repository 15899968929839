import { useState } from 'react'
import { checkExpiry, getSignedUrl } from '../utils/AWS'
import _ from 'lodash'
import mixpanelEvents from '../config/mixpanelEvents'
import trackEvent from '../utils/TrackEvent/TrackEvent'
import { PDFDocument } from 'pdf-lib'
import { toast } from 'react-toastify'
import { initalizeDownload } from '../utils/DownloadFromS3/DownloadFromS3'

/**
 * Custom hook for opening images from S3
 * @param {Object} s3Obj - S3 configuration object
 * @returns {Object} Object with image handler functions and state
 */
export const useOpenImage = (s3Obj) => {
  const [selectedScreenshots, setSelectedScreenshots] = useState([])

  /**
   * Opens images from S3 with signed URLs
   * @param {Array|String} payload - Image URL or array of image objects
   * @param {String} [proposalId] - Optional proposal ID for tracking
   */
  const openImage = async (payload = [], proposalId = null) => {
    const url = _.cloneDeep(payload)

    if (_.isArray(url)) {
      await Promise.all(
        url.map(async (img, index) => {
          const signedSrc = await checkExpiry(img.src, s3Obj)
          url[index].src = signedSrc
        })
      )
      setSelectedScreenshots(url)
    } else {
      const signedSrc = await checkExpiry(url, s3Obj)
      setSelectedScreenshots([{ src: signedSrc }])
    }

    if (proposalId) {
      trackEvent(
        mixpanelEvents.PEOPLE_RESUME_SCREENSHOT_VIEWED,
        'SUCCESS',
        {},
        { proposal_id: proposalId }
      )
    }
  }

  /**
   * Closes the image gallery
   */
  const closeImage = () => {
    setSelectedScreenshots([])
  }

  return {
    selectedScreenshots,
    openImage,
    closeImage,
    hasOpenImage: selectedScreenshots.length > 0
  }
}

/**
 * Custom hook for opening and downloading PDFs
 * @param {Object} s3Obj - S3 configuration object
 * @param {String} domainId - The domain ID for the organization
 * @returns {Object} Object with PDF handler functions
 */
export const usePdfHandlers = (s3Obj, domainId) => {
  /**
   * Opens a PDF in a new tab
   * @param {String} proposalId - The ID of the proposal
   * @param {Number} pageNumber - The page number to open (defaults to 0)
   */
  const openPdf = (proposalId, pageNumber = 0) => {
    pageNumber = pageNumber || 0

    trackEvent(
      mixpanelEvents.PEOPLE_RESUME_PROPOSAL_VIEWED,
      'NAVIGATION',
      {
        proposal_id: proposalId,
        page_number: pageNumber
      },
      {}
    )

    const path = `/view/pdf/${proposalId}/${pageNumber}`
    if (path) {
      window.open(path, '_blank')
    }
  }

  /**
   * Downloads a specific page from a PDF
   * @param {String} proposalId - The ID of the proposal
   * @param {Number} pageNumber - The page number to download
   * @param {String} thumbnailUrl - Fallback thumbnail URL if PDF download fails
   * @param {String} pdfName - Preferred name for the downloaded file
   */
  const downloadPdf = async (
    proposalId,
    pageNumber,
    thumbnailUrl,
    pdfName = 'resume'
  ) => {
    const toastId = toast.loading('Downloading Resume PDF...')

    try {
      const pdfUrl = `https://${process.env.REACT_APP_PDF_BUCKET}.s3.amazonaws.com/raw-pdfs/${domainId}/${proposalId}.pdf`
      const finalUrl = await getSignedUrl(pdfUrl, s3Obj, `${proposalId}.pdf`)
      const arrayBuffer = await fetch(finalUrl).then((res) => res.arrayBuffer())
      const pdfDoc = await PDFDocument.load(arrayBuffer)
      const newPdf = await PDFDocument.create()

      const pagesToBeCopied = [pageNumber]
      const copiedPages = await newPdf.copyPages(pdfDoc, pagesToBeCopied)

      for (const copiedPage of copiedPages) {
        newPdf.addPage(copiedPage)
      }

      const pdfBytes = await newPdf.save()
      const blob = new Blob([pdfBytes], { type: 'application/pdf' })

      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = pdfName
      link.click()

      trackEvent(mixpanelEvents.PEOPLE_RESUME_DOWNLOAD, 'SUCCESS', {
        proposal_id: proposalId
      })
    } catch (error) {
      console.error('Error downloading PDF:', error)
      if (thumbnailUrl) {
        await initalizeDownload(thumbnailUrl, pdfName + '.png', s3Obj)
      }
    } finally {
      toast.dismiss(toastId)
    }
  }

  return {
    openPdf,
    downloadPdf
  }
}

export default {
  useOpenImage,
  usePdfHandlers
}
