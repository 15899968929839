import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import HeadlessTable from '../../../components/TableV2/HeadlessTable'
import { fetchProjects } from '../../../store/Project/Actions'
import ProjectResult from '../../../sections/ProjectResult/ProjectResult'
import { initalizeS3 } from '../../../utils/AWS'
import { getParticularProject } from '@/store/api'
import { SemiCircleIcon } from '@/components/Icons/Icons'
import SearchNew from '@/sections/ListFilter/SearchNew'
import { usePdfHandlers } from '@/hooks/PdfHooks'

const ProjectSelector = ({ projectMatrixData, setProjectMatrixData }) => {
  const [loading, setLoading] = useState(true)
  const [rows, setRows] = useState([])
  const [meta, setMeta] = useState({})
  const [page, setPage] = useState(1)
  const [selectedProject, setSelectedProject] = useState(null)
  const [currentProject, setCurrentProject] = useState(null)
  const [selectedProjectLoading, setSelectedProjectLoading] = useState(false)
  const [s3Obj, setS3Obj] = useState(null)
  const dispatch = useDispatch()
  const [selectedData, setSelectedData] = useState([])
  const [search, setSearch] = useState('')
  const auth = useSelector((state) => state.authenticate)
  const domain = auth?.user?.domain
  const { domain_id = '' } = domain || {}
  const { openPdf, downloadPdf } = usePdfHandlers(s3Obj, domain_id)
  // Columns configuration for the HeadlessTable
  const columns = [
    {
      accessorKey: 'title',
      label: 'Project Name',
      style: { width: '100%' },
      children: (row) => {
        return (
          <div className="flex items-center justify-between w-full p-1 text-sm font-medium text-zinc-800">
            {row.title}
          </div>
        )
      }
    }
  ]

  useEffect(() => {
    const timeout = setTimeout(() => {
      fetchData(page)
    }, 2000)
    return () => clearTimeout(timeout)
  }, [search])

  useEffect(() => {
    if (projectMatrixData.length > 0) {
      setSelectedData(projectMatrixData.map((project) => project.id))
    }
  }, [])

  // Fetch projects data
  const fetchData = (pageNum = 1) => {
    setLoading(true)
    const req = {
      page_num: pageNum,
      page_size: 25,
      keyword: search
    }

    dispatch(
      fetchProjects(req, {}, (data) => {
        const newData =
          data?.project?.map((item) => ({
            ...item,
            location: item?.data?.project_location
          })) || []

        setRows(newData)
        setLoading(false)
        setMeta({
          pageSize: 25,
          totalCount: data?.total_count || 0,
          page: data?.page_num || 1
        })
      })
    )
  }

  // Handle row click to display project details
  const handleRowClick = (row) => {
    // setSelectedProject(row)
    setSelectedProjectLoading(true)
    getParticularProject(row.id)
      .then((res) => {
        setCurrentProject(res.data.project)
        setSelectedProject(res.data)
        setSelectedProjectLoading(false)
      })
      .catch(() => {
        setSelectedProjectLoading(false)
      })
  }

  const handleSelectedData = (row) => {
    if (selectedData.includes(row.id)) {
      setSelectedData(selectedData.filter((id) => id !== row.id))
      setProjectMatrixData(
        projectMatrixData.filter((project) => project.id !== row.id)
      )
    } else {
      setSelectedData([...selectedData, row.id])
      setProjectMatrixData([...projectMatrixData, row])
    }
  }
  // Initialize S3 and fetch data on component mount
  useEffect(() => {
    const initialize = async () => {
      const s3 = await initalizeS3()
      setS3Obj(s3)
      fetchData(page)
    }
    initialize()
  }, [])

  // Fetch data when page changes
  useEffect(() => {
    fetchData(page)
  }, [page])

  // Handle closing a project
  const handleCloseProject = () => {
    setSelectedProject(null)
    setCurrentProject(null)
  }

  return (
    <div className="w-full border-t twp border-zinc-100">
      <div
        className="grid w-full grid-cols-2"
        style={{ height: 'calc(100% - 55px)' }}
      >
        {/* Left panel - Projects list */}
        <div className="p-2 border-r border-zinc-100">
          <div className="p-2">
            <SearchNew
              value={search}
              placeholder="Search projects"
              onChange={(value) => {
                setSearch(value)
              }}
            />
          </div>
          <HeadlessTable
            leftAlign={true}
            hideScrollbar={true}
            hideHeader={true}
            //   withoutStyles={true}
            //   hideMultiSelect={true}
            columns={columns}
            selectedData={selectedData}
            handleSelectedData={handleSelectedData}
            data={rows}
            meta={meta}
            loading={loading}
            onRowClick={handleRowClick}
            fetchPage={(pageNum) => setPage(pageNum)}
            style={{ height: 'calc(-190px + 90vh)', overflow: 'auto' }}
          />
        </div>

        {/* Right panel - Project details */}
        <div
          className=""
          style={{
            height: 'calc(100vh - 150px)',
            overflow: 'auto'
          }}
        >
          {selectedProjectLoading ? (
            <div className="grid w-full h-full place-content-center">
              <SemiCircleIcon className="size-4 animate-spin-fast" />
            </div>
          ) : currentProject ? (
            <ProjectResult
              v2={true}
              key={currentProject?.id}
              refresh={() => fetchData(page)}
              currentProject={currentProject}
              setCurrentProject={setCurrentProject}
              data={currentProject}
              handleCloseProject={handleCloseProject}
              s3Obj={s3Obj}
              openPdf={openPdf}
              projectId={currentProject?.id}
              projectTitle={currentProject?.data?.project_name}
              showBackButton={true}
            />
          ) : (
            <div className="flex items-center justify-center h-full">
              <p className="text-grey-500">Select a project to view details</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProjectSelector
