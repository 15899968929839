import { ImageRender } from '@/components'
import Button2 from '@/components/Button/Button2'
import {
  MaximizeIcon,
  RedirectIcon,
  ThreeDotsIcon
} from '@/components/Icons/Icons'
import { Box, Fade, Menu, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SearchNew from '../ListFilter/SearchNew'
import { contentSearch } from '@/store/api'
import _ from 'lodash'
import trackEvent from '@/utils/TrackEvent/TrackEvent'
import mixpanelEvents from '@/config/mixpanelEvents'
import AttachFilesUI from '@/components/AttachFilesUI/AttachFilesUI'
const ProjectResumes = ({
  projectsData = [],
  handleDownloadCustomPdf,
  handleMasterMappingDelete,
  viewImage,
  openPdf,
  s3Obj,
  isUserViewer = false,
  files = [],
  setFiles = () => {},
  projectId = ''
}) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredProjects, setFilteredProjects] = useState(projectsData)
  const [contentSearching, setContentSearching] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  useEffect(() => {
    if (searchQuery === '') {
      setFilteredProjects(projectsData)
      return
    }
    const timeout = setTimeout(() => {
      setContentSearching(true)
      trackEvent(
        mixpanelEvents.PROJECT_DOCUMENT_CONTENT_SEARCHED,
        'SUCCESS',
        {},
        {
          project_id: currentProject?.id,
          search_query: searchQuery
        }
      )

      contentSearch({
        group_id: currentProject?.id,
        search_keyword: searchQuery,
        search_type: 'project'
      })
        .then((res) => {
          console.log()
          const foundList = res.data.data
          setContentSearching(false)
          const filtered = currentProject.data.filter((project) => {
            return foundList.includes(project.id)
          })

          setFilteredProjects(filtered)
        })
        .catch((err) => {
          console.log(err)
          setContentSearching(false)
        })
    }, 1500)

    return () => clearTimeout(timeout)
  }, [searchQuery])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  if (projectsData?.length === 0) {
    return (
      <div className="grid h-full place-content-center text-sm text-grey-500 justify-items-center">
        <div>No resumes found.</div>
        <div>Please try attaching a case study or project sheet.</div>
        {files && files.length === 0 && (
          <div className="h-60 w-full">
            <AttachFilesUI
              files={files}
              setFiles={setFiles}
              profileId={projectId}
              source="project"
              isUserViewer={isUserViewer}
            />
          </div>
        )}
      </div>
    )
  }

  return (
    <div
      style={{
        padding: '10px 20px'
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
          gap: '6px',
          padding: '8px 0px',
          marginBottom: '5px'
        }}
      >
        <SearchNew
          value={searchQuery}
          onChange={(value) => {
            setSearchQuery(value)
          }}
          placeholder="Search Docs"
          onClear={() => {
            setSearchQuery('')
          }}
        />
      </div>
      <div className="">
        {contentSearching ? (
          <div className="grid place-content-center">Seraching Content</div>
        ) : (
          <div className="grid gap-4 grid-col-5">
            {filteredProjects?.map((project, index) => {
              const {
                proposal_id,
                thumbnail_url = '',
                screenshots = [],
                page_number = 1,
                proposal_name = '',
                version = '',
                screenShot,
                client_group,
                title
              } = project
              const coverImage = _.isEmpty(screenshots)
                ? thumbnail_url
                : screenshots[0]?.src
              const imagePayload = _.isEmpty(screenshots)
                ? thumbnail_url
                : screenshots
              const displayName = proposal_name || proposal_id
              const sourceType =
                client_group?.type === 'rfx' ? 'rfx' : 'proposal'

              if (!proposal_id) return null
              return (
                <Box
                  key={index}
                  className="parent-card"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    border: '1px solid var(--grey-200)',
                    overflow: 'hidden',
                    borderRadius: '10px',
                    '&:hover img': {
                      opacity: '0.5'
                    },
                    '&:hover #overlayMenu': {
                      opacity: '0.8 !important'
                    }
                  }}
                >
                  <Box
                    id={'project_' + index}
                    key={'project_' + index}
                    style={{
                      padding: '10px',
                      position: 'relative'
                    }}
                  >
                    <Box
                      sx={{
                        justifyContent: 'center',
                        width: '100%',
                        display: 'flex',
                        cursor: 'pointer'
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        <ImageRender
                          style={{
                            height: '100%',
                            width: '100%',
                            minWidth: '180px',
                            maxWidth: '200px',
                            minHeight: '220px',
                            objectFit: 'contain',
                            overflow: 'hidden',
                            transition: 'opacity 0.5s ease'
                          }}
                          src={thumbnail_url}
                          s3Obj={s3Obj}
                          // effect="blur"
                          overlay={
                            <Box
                              id="overlayMenu"
                              style={{
                                position: 'absolute',
                                top: '0%',
                                right: '0%',
                                opacity: 0,
                                backgroundColor: 'var(--grey-700)',
                                padding: '8px',
                                width: '100%',
                                height: '100%',
                                boxSizing: 'border-box',
                                zIndex: 1000,
                                transition: 'opacity 0.3s ease'
                              }}
                            >
                              <div className="relative h-full">
                                {thumbnail_url && (
                                  <div
                                    className="absolute"
                                    style={{
                                      top: '45%',
                                      left: '45%'
                                    }}
                                    onClick={() => {
                                      viewImage([{ src: thumbnail_url }])
                                    }}
                                  >
                                    <MaximizeIcon className="size-6 text-grey-100" />
                                  </div>
                                )}

                                {client_group?.id && (
                                  <Button2
                                    secondary
                                    disableHover
                                    onClick={() => {
                                      window.open(
                                        `/library/companies/${client_group?.id}`,
                                        '_blank'
                                      )
                                    }}
                                    style={{
                                      border: '1px solid var(--grey-100)',
                                      fontSize: '12px',
                                      position: 'absolute',
                                      bottom: '10px',
                                      width: '100%',
                                      color: 'var(--grey-100)',
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: '8px'
                                    }}
                                  >
                                    View Client{' '}
                                    <RedirectIcon className="size-3" />
                                  </Button2>
                                )}
                              </div>
                            </Box>
                          }
                          fallback={
                            <>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  wordBreak: 'break-word'
                                }}
                              >
                                <Box
                                  sx={{
                                    fontWeight: '600',
                                    fontSize: '16px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    '-webkit-line-clamp': 2,
                                    display: '-webkit-box',
                                    '-webkit-box-orient': 'vertical'
                                  }}
                                >
                                  {displayName}
                                </Box>
                              </Box>
                            </>
                          }
                        />
                      </Box>
                    </Box>
                  </Box>
                  <div className="flex items-center justify-between p-3 bg-grey-100">
                    {proposal_name ? (
                      <Tooltip title={proposal_name}>
                        <p
                          className="m-0 text-xs font-medium cursor-pointer"
                          style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                          }}
                          onClick={() => {
                            openPdf(proposal_id, page_number, sourceType)
                          }}
                        >
                          {proposal_name}
                        </p>
                      </Tooltip>
                    ) : (
                      <p className="m-0 text-sm font-medium">
                        Untitled Project
                      </p>
                    )}

                    <div className="relative w-16 h-full card-options">
                      <div className="flex justify-end rounded text-grey-700">
                        <span
                          onClick={(e) => handleClick(e, project)}
                          className="grid cursor-pointer place-content-center"
                        >
                          <ThreeDotsIcon className="size-5" strokeWidth={2} />
                        </span>
                        <Menu
                          elevation={2}
                          TransitionComponent={Fade}
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleCloseMenu}
                          MenuListProps={{
                            'aria-labelledby': 'basic-button'
                          }}
                          className="p-1"
                        >
                          <Button2
                            noOutline
                            secondary
                            style={{
                              width: '100%'
                            }}
                            onClick={() =>
                              handleDownloadCustomPdf(
                                proposal_id,
                                page_number - 1,
                                proposal_name,
                                thumbnail_url,
                                sourceType
                              )
                            }
                          >
                            Download Resume
                          </Button2>
                          {/* <Button2
                          noOutline
                          secondary
                          style={{
                            width: '100%'
                          }}
                          onClick={() => handleMove(resume)}
                        >
                          Move Resume
                        </Button2> */}
                          <Button2
                            noOutline
                            secondary
                            style={{
                              width: '100%'
                            }}
                            onClick={() => {
                              handleMasterMappingDelete(
                                // project,
                                index,
                                'delete'
                              )
                            }}
                          >
                            Remove Resume
                          </Button2>
                        </Menu>
                      </div>
                    </div>
                  </div>
                </Box>
              )
            })}
          </div>
        )}

        {filteredProjects?.length === 0 && (
          <div className="grid place-content-center">No results found</div>
        )}
      </div>
    </div>
  )
}

export default ProjectResumes
