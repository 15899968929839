import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Checkbox } from '@/components/ui/checkbox'
import { fetchProjects } from '@/store/Project/Actions'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  DialogClose
} from '@/components/ui/dialog'
import SearchNew from '@/sections/ListFilter/SearchNew'
import Button2 from '@/components/Button/Button2'

const AddProjectDialog = ({
  isOpen,
  onClose,
  onAddProjects,
  existingProjects
}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [dialogLoading, setDialogLoading] = useState(false)
  const [searchResults, setSearchResults] = useState([])
  const [selectedProjects, setSelectedProjects] = useState([])

  const dispatch = useDispatch()

  // Reset dialog state when opening
  useEffect(() => {
    if (isOpen) {
      setSearchTerm('')
      setSelectedProjects([])
      fetchSearchResults()
    }
  }, [isOpen, existingProjects])

  // Search functionality
  useEffect(() => {
    if (isOpen) {
      const timeoutId = setTimeout(() => {
        fetchSearchResults()
      }, 500)
      return () => clearTimeout(timeoutId)
    }
  }, [searchTerm, isOpen])

  const fetchSearchResults = () => {
    setDialogLoading(true)
    const req = {
      page_num: 1,
      page_size: 50,
      keyword: searchTerm
    }

    dispatch(
      fetchProjects(req, {}, (data) => {
        // Filter out projects already in the matrix
        const availableProjects =
          data?.project?.filter(
            (project) => !existingProjects.some((p) => p.id === project.id)
          ) || []

        setSearchResults(availableProjects)
        setDialogLoading(false)
      })
    )
  }

  const toggleProjectSelection = (project) => {
    setSelectedProjects((prevSelected) => {
      if (prevSelected.some((p) => p.id === project.id)) {
        return prevSelected.filter((p) => p.id !== project.id)
      } else {
        return [...prevSelected, project]
      }
    })
  }

  const handleAddProjects = () => {
    onAddProjects(selectedProjects)
    onClose()
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[700px]">
        <DialogHeader>
          <DialogTitle>Add Projects</DialogTitle>
          <DialogDescription>
            Search and select projects to add to the matrix
          </DialogDescription>
        </DialogHeader>

        {/* Dialog Content */}
        <div className="py-4">
          <div className="mb-4">
            <SearchNew
              value={searchTerm}
              onChange={(value) => setSearchTerm(value)}
              placeholder="Search projects..."
              className="w-full p-2 border border-gray-300 rounded-md"
            />
          </div>

          <div className="max-h-[300px] overflow-y-auto">
            {dialogLoading ? (
              <div className="flex items-center justify-center h-40">
                <span className="text-gray-500">Loading...</span>
              </div>
            ) : searchResults.length === 0 && searchTerm !== '' ? (
              <div className="flex items-center justify-center h-40">
                <span className="text-gray-500">No projects found</span>
              </div>
            ) : (
              <div className="grid grid-cols-1 gap-2">
                {searchResults.map((project) => (
                  <div
                    key={project.id}
                    className={`flex items-center p-2 border rounded-md cursor-pointer ${
                      selectedProjects.some((p) => p.id === project.id)
                        ? 'border-zinc-300 bg-zinc-50'
                        : 'border-zinc-100 hover:bg-zinc-50'
                    }`}
                    onClick={() => toggleProjectSelection(project)}
                  >
                    <div className="flex items-center w-full gap-3">
                      <Checkbox
                        checked={selectedProjects.some(
                          (p) => p.id === project.id
                        )}
                      />
                      <div className="flex flex-col">
                        <span className="text-sm font-medium">
                          {project.title}
                        </span>
                        {project.description && (
                          <span className="text-xs text-gray-500 line-clamp-1">
                            {project.description}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <DialogFooter>
          <DialogClose asChild>
            <button className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200">
              Cancel
            </button>
          </DialogClose>
          <Button2
            onClick={handleAddProjects}
            disabled={selectedProjects.length === 0}
            primary
          >
            Add{' '}
            {selectedProjects.length > 0 ? `(${selectedProjects.length})` : ''}
          </Button2>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default AddProjectDialog
