import React, { useState, useEffect, useRef } from 'react'
import { Checkbox } from '@/components/ui/checkbox'
import { createSwapy } from 'swapy'
import {
  ChevronRightIcon,
  CrossIcon,
  DownloadIcon2,
  DragDotsIcon,
  PlusIcon
} from '@/components/Icons/Icons'
import { ImageAvatarFallback } from '@/components'
import { initalizeS3 } from '@/utils/AWS'
import * as XLSX from 'xlsx'
import AddPeopleDialog from './AddPeopleDialog'
import AddProjectDialog from './AddProjectDialog'
import { updateMatrixData } from '@/store/api'

// Create a simplified drag and drop component based on the example
const DraggableGrid = ({
  peopleMatrixData,
  projectMatrixData,
  relationData,
  workspaceId,
  goBack
}) => {
  const [matrixData, setMatrixData] = useState({})
  const [loading, setLoading] = useState(true)
  const [people, setPeople] = useState([])
  const [projects, setProjects] = useState([])
  const [showAddPeopleDialog, setShowAddPeopleDialog] = useState(false)
  const [showAddProjectDialog, setShowAddProjectDialog] = useState(false)
  const projectsContainerRef = useRef(null)
  const headerScrollRef = useRef(null)
  const relationshipDataRef = useRef(relationData)
  const projectsSwapyRef = useRef(null)
  // Add custom styles for Swapy
  useEffect(() => {
    // Add styles to fix touch and drag issues
    const styleElement = document.createElement('style')
    styleElement.textContent = `
      .swapy-container {
        overscroll-behavior: contain;
      }
      [data-swapy-item] {
        touch-action: none;
        user-select: none;
      }
      [data-swapy-slot] {
        position: relative;
      }
      .swapy-dragging {
        z-index: 1000;
        opacity: 0.8;
      }
      .swapy-highlighted {
        background-color: rgba(59, 130, 246, 0.1);
        border: 2px dashed #3b82f6;
      }
      .matrix-header {
        position: sticky;
        top: 0;
        z-index: 10;
        background: #f9fafb;
        box-shadow: 0 1px 3px rgba(0,0,0,0.1);
      }
      .matrix-container {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 120px);
        overflow: hidden; /* Important to prevent double scrollbars */
      }
      .matrix-body {
        flex: 1;
        overflow: auto;
        // scrollbar-width: thin;
      }
      .dialog-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 50;
      }
      .dialog-content {
        background: white;
        border-radius: 0.5rem;
        width: 90%;
        max-width: 700px;
        max-height: 80vh;
        overflow: hidden;
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
      }
    `
    document.head.appendChild(styleElement)

    return () => {
      // Clean up
      document.head.removeChild(styleElement)
    }
  }, [])

  // Initialize matrix data when peopleMatrixData or projectMatrixData changes
  useEffect(() => {
    if (peopleMatrixData.length > 0 && projectMatrixData.length > 0) {
      setPeople([...peopleMatrixData])
      setProjects([...projectMatrixData])

      const initialMatrix = {}
      projectMatrixData.forEach((project) => {
        initialMatrix[project.id] = {}
        peopleMatrixData.forEach((person) => {
          initialMatrix[project.id][person.id] = false
        })
      })

      setMatrixData(initialMatrix)
      setLoading(false)
    }
  }, [peopleMatrixData, projectMatrixData, relationData])

  // Sync horizontal scrolling between header and body
  useEffect(() => {
    const projectsContainer = projectsContainerRef.current
    const headerContainer = headerScrollRef.current

    if (!projectsContainer || !headerContainer) return

    // Disable pointer events on header to prevent direct interaction
    // that would break sync, but enable them when dragging
    const headerParent = headerContainer.parentElement
    if (headerParent) {
      // headerParent.style.pointerEvents = 'none'
    }

    const handleProjectsScroll = () => {
      // Sync the horizontal scroll position from the projects container to the header
      headerContainer.scrollLeft = projectsContainer.scrollLeft
    }

    // Initial sync
    handleProjectsScroll()

    // Listen for scroll events on the projects container
    projectsContainer.addEventListener('scroll', handleProjectsScroll)

    return () => {
      projectsContainer.removeEventListener('scroll', handleProjectsScroll)
      if (headerParent) {
        headerParent.style.pointerEvents = ''
      }
    }
  }, [loading])

  // Enable pointerEvents only during dragging operations
  useEffect(() => {
    function enablePointerEvents() {
      const headerParent = headerScrollRef.current?.parentElement
      if (headerParent) {
        headerParent.style.pointerEvents = 'auto'
      }
    }

    function disablePointerEvents() {
      const headerParent = headerScrollRef.current?.parentElement
      if (headerParent) {
        // headerParent.style.pointerEvents = 'none'
      }
    }

    document.addEventListener('mousedown', enablePointerEvents)
    document.addEventListener('touchstart', enablePointerEvents)
    document.addEventListener('mouseup', disablePointerEvents)
    document.addEventListener('touchend', disablePointerEvents)

    return () => {
      document.removeEventListener('mousedown', enablePointerEvents)
      document.removeEventListener('touchstart', enablePointerEvents)
      document.removeEventListener('mouseup', disablePointerEvents)
      document.removeEventListener('touchend', disablePointerEvents)
    }
  }, [])

  // Initialize people (columns) dragging

  // Initialize projects (rows) dragging
  useEffect(() => {
    // Wait until the ref is available and data is loaded
    if (!projectsContainerRef.current || loading || projects.length === 0)
      return

    // Cleanup previous instance if exists
    if (projectsSwapyRef.current) {
      projectsSwapyRef.current.destroy()
      projectsSwapyRef.current = null
    }

    // Create new Swapy instance for projects
    projectsSwapyRef.current = createSwapy(projectsContainerRef.current, {
      animation: 'dynamic',
      direction: 'vertical',
      dragAxis: 'x',
      autoScrollOnDrag: true
    })

    // Listen for swap events
    projectsSwapyRef.current.onSwap((event) => {
      console.log('Projects swap event:', event)
    })

    projectsSwapyRef.current.onSwapEnd((event) => {
      if (event.hasChanged) {
        onProjectOrderChange(event.slotItemMap.asArray)
      }
    })

    return () => {
      if (projectsSwapyRef.current) {
        projectsSwapyRef.current.destroy()
      }
    }
  }, [loading, projects])

  // Handle checkbox changes
  const handleCheckboxChange = (projectId, personId, checked) => {
    setMatrixData((prev) => ({
      ...prev,
      [projectId]: {
        ...prev[projectId],
        [personId]: checked
      }
    }))

    let relationshipDataTemp = { ...relationshipDataRef.current }
    if (checked) {
      relationshipDataTemp = {
        ...relationshipDataRef.current,
        [projectId]: [
          ...(relationshipDataRef.current[projectId] || []),
          personId
        ]
      }
    } else {
      relationshipDataTemp = {
        ...relationshipDataRef.current,
        [projectId]: relationshipDataRef.current[projectId].filter(
          (id) => id !== personId
        )
      }
    }

    relationshipDataRef.current = relationshipDataTemp
    saveMatrixData(relationshipDataTemp)
  }

  const saveMatrixData = async (relationshipData) => {
    const projectIdsOrder = []
    const peopleIdsOrder = []
    projectMatrixData.forEach((project) => {
      projectIdsOrder.push(project.id)
    })

    peopleMatrixData.forEach((person) => {
      peopleIdsOrder.push(person.id)
    })

    const data = {
      workspace_id: workspaceId,
      relationship_data: relationshipData,
      project_ids_order: projectIdsOrder,
      people_ids_order: peopleIdsOrder
    }
    const response = await updateMatrixData(data)
    console.log('response', response)

    if (response.status === 200) {
    }
  }

  const onPeopleOrderChange = async (slotItemMap) => {
    const newPeople = []
    slotItemMap.forEach((item) => {
      const person = people.find((p) => p.id === item.item)
      if (person) {
        newPeople.push(person)
      }
    })
    console.log(
      'New mapping:',
      people,
      newPeople,
      matrixData,
      relationshipDataRef.current
    )

    setPeople(newPeople)

    const projectIdsOrder = []
    const peopleIdsOrder = []

    projects.forEach((project) => {
      projectIdsOrder.push(project.id)
    })

    newPeople.forEach((person) => {
      peopleIdsOrder.push(person.id)
    })

    const data = {
      workspace_id: workspaceId,
      relationship_data: relationshipDataRef.current,
      project_ids_order: projectIdsOrder,
      people_ids_order: peopleIdsOrder
    }

    const response = await updateMatrixData(data)

    if (response.status === 200) {
    }
  }

  const onProjectOrderChange = async (slotItemMap) => {
    const newProjects = []
    slotItemMap.forEach((item) => {
      const project = projects.find((p) => p.id === item.item)
      if (project) {
        newProjects.push(project)
      }
    })

    setProjects(newProjects)

    const projectIdsOrder = []
    const peopleIdsOrder = []

    projects.forEach((project) => {
      projectIdsOrder.push(project.id)
    })

    people.forEach((person) => {
      peopleIdsOrder.push(person.id)
    })

    const data = {
      workspace_id: workspaceId,
      relationship_data: relationshipDataRef.current,
      project_ids_order: projectIdsOrder,
      people_ids_order: peopleIdsOrder
    }

    const response = await updateMatrixData(data)

    if (response.status === 200) {
    }
  }
  // Function to export matrix data to Excel
  const exportToExcel = () => {
    // Validate if there's data to export
    if (people.length === 0 || projects.length === 0) {
      console.error('No data to export')
      return
    }

    try {
      // Prepare the data for Excel
      const excelData = []

      // Create header row with people names
      const headerRow = ['Projects / People']
      people.forEach((person) => {
        headerRow.push(person.name || 'Unknown Person')
      })
      excelData.push(headerRow)

      // Create rows for each project
      projects.forEach((project) => {
        const projectRow = [project.title || 'Unknown Project']
        people.forEach((person) => {
          // Add 'Yes' or 'No' based on checkbox state
          projectRow.push(matrixData[project.id]?.[person.id] ? 'X' : '-')
        })
        excelData.push(projectRow)
      })

      // Create worksheet
      const ws = XLSX.utils.aoa_to_sheet(excelData)

      // Set column widths for better readability
      const columnWidths = [{ wch: 20 }] // Projects column
      for (let i = 0; i < people.length; i++) {
        columnWidths.push({ wch: 15 }) // People columns
      }
      ws['!cols'] = columnWidths

      // Create workbook
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Matrix')

      // Generate file name with timestamp
      const timestamp = new Date()
        .toISOString()
        .replace(/[:.]/g, '-')
        .substring(0, 19)
      const fileName = `Project_People_Matrix_${timestamp}.xlsx`

      // Generate Excel file and download
      XLSX.writeFile(wb, fileName)
    } catch (error) {
      console.error('Error exporting to Excel:', error)
    }
  }

  // Add new people to the matrix
  const handleAddPeople = async (newPeople) => {
    // Filter out already existing people
    const filteredNewPeople = newPeople.filter(
      (newPerson) => !people.some((person) => person.id === newPerson.id)
    )

    if (filteredNewPeople.length === 0) return

    // Add new people to the state
    const updatedPeople = [...people, ...filteredNewPeople]
    setPeople(updatedPeople)

    console.log('updatedPeople', updatedPeople)
    // Initialize matrix data for new people
    const updatedMatrixData = { ...matrixData }
    projects.forEach((project) => {
      if (!updatedMatrixData[project.id]) {
        updatedMatrixData[project.id] = {}
      }

      filteredNewPeople.forEach((person) => {
        updatedMatrixData[project.id][person.id] = false
      })
    })

    setMatrixData(updatedMatrixData)

    const projectIdsOrder = []
    const peopleIdsOrder = []

    projects.forEach((project) => {
      projectIdsOrder.push(project.id)
    })

    updatedPeople.forEach((person) => {
      peopleIdsOrder.push(person.id)
    })

    const data = {
      workspace_id: workspaceId,
      relationship_data: relationshipDataRef.current,
      project_ids_order: projectIdsOrder,
      people_ids_order: peopleIdsOrder
    }
    const response = await updateMatrixData(data)

    if (response.status === 200) {
      setShowAddPeopleDialog(false)
    }
  }

  const handleRemovePeople = async (personId) => {
    const updatedPeople = people.filter((person) => person.id !== personId)
    setPeople(updatedPeople)

    const projectIdsOrder = []
    const peopleIdsOrder = []

    projects.forEach((project) => {
      projectIdsOrder.push(project.id)
    })

    updatedPeople.forEach((person) => {
      peopleIdsOrder.push(person.id)
    })

    const data = {
      workspace_id: workspaceId,
      relationship_data: relationshipDataRef.current,
      project_ids_order: projectIdsOrder,
      people_ids_order: peopleIdsOrder
    }
    const response = await updateMatrixData(data)

    if (response.status === 200) {
    }
  }

  const handleRemoveProject = async (projectId) => {
    const updatedProjects = projects.filter(
      (project) => project.id !== projectId
    )
    setProjects(updatedProjects)

    const projectIdsOrder = []
    const peopleIdsOrder = []

    updatedProjects.forEach((project) => {
      projectIdsOrder.push(project.id)
    })

    people.forEach((person) => {
      peopleIdsOrder.push(person.id)
    })

    const data = {
      workspace_id: workspaceId,
      relationship_data: relationshipDataRef.current,
      project_ids_order: projectIdsOrder,
      people_ids_order: peopleIdsOrder
    }

    const response = await updateMatrixData(data)

    if (response.status === 200) {
    }
  }

  const handleAddPeopleClick = () => {
    setShowAddPeopleDialog(true)
  }

  // Add new projects to the matrix
  const handleAddProjects = async (newProjects) => {
    // Filter out already existing projects
    const filteredNewProjects = newProjects.filter(
      (newProject) => !projects.some((project) => project.id === newProject.id)
    )

    if (filteredNewProjects.length === 0) return

    // Add new projects to the state
    const updatedProjects = [...projects, ...filteredNewProjects]
    setProjects(updatedProjects)

    // Initialize matrix data for new projects
    const updatedMatrixData = { ...matrixData }
    filteredNewProjects.forEach((project) => {
      updatedMatrixData[project.id] = {}
      people.forEach((person) => {
        updatedMatrixData[project.id][person.id] = false
      })
    })

    setMatrixData(updatedMatrixData)

    const projectIdsOrder = []
    const peopleIdsOrder = []

    updatedProjects.forEach((project) => {
      projectIdsOrder.push(project.id)
    })

    people.forEach((person) => {
      peopleIdsOrder.push(person.id)
    })

    const data = {
      workspace_id: workspaceId,
      relationship_data: relationshipDataRef.current,
      project_ids_order: projectIdsOrder,
      people_ids_order: peopleIdsOrder
    }
    const response = await updateMatrixData(data)

    if (response.status === 200) {
      setShowAddProjectDialog(false)
    }
  }

  const handleAddProjectClick = () => {
    setShowAddProjectDialog(true)
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        Loading matrix...
      </div>
    )
  }

  if (people.length === 0 || projects.length === 0) {
    return (
      <div className="flex items-center justify-center h-full">
        Please select at least one person and one project to view the matrix.
      </div>
    )
  }

  return (
    <div className="flex flex-col w-full h-full twp">
      <div className="flex items-center justify-between p-2 px-4">
        <div
          className="flex items-center gap-1 text-sm font-medium cursor-pointer"
          onClick={() => goBack()}
        >
          <ChevronRightIcon className="rotate-180 size-4" /> Back to Selection
        </div>

        <button
          onClick={exportToExcel}
          className="flex items-center gap-2 p-2 px-4 text-xs font-medium text-white rounded-md bg-zinc-800 hover:bg-zinc-700 focus:outline-none focus:ring-2 focus:ring-zinc-500 focus:ring-offset-2"
          aria-label="Export to Excel"
        >
          <DownloadIcon2 className="size-4" strokeWidth={1.5} />
          Export to Excel
        </button>
      </div>
      <div className="relative mx-4 mb-4 border border-gray-200 rounded-md matrix-container">
        {/* Header row with people names - sticky to top */}
        <div className="flex border-b border-gray-200 matrix-header">
          {/* Empty cell for the top-left corner */}
          <div className="sticky left-0 z-20 flex-shrink-0 w-64 p-2 text-sm font-semibold border-r border-gray-200 min-w-64 bg-gray-50">
            Projects / People
          </div>

          {/* People (columns) draggable container */}
          <div
            ref={headerScrollRef}
            className="overflow-hidden"
            style={{ maxWidth: 'calc(100vw - 200px)' }}
          >
            <PeopleCells
              key={people.length}
              people={people}
              onPeopleOrderChange={onPeopleOrderChange}
              onAddPeople={handleAddPeopleClick}
              onRemovePeople={handleRemovePeople}
            />
          </div>
        </div>

        {/* Projects (rows) draggable container - This is where all scrolling happens */}
        <div ref={projectsContainerRef} className="matrix-body">
          <div className="min-w-max">
            {projects.map((project, index) => (
              <div
                key={project.id}
                data-swapy-slot={project.id}
                className="w-full"
              >
                <div
                  data-swapy-item={project.id}
                  className="flex w-full border-b border-gray-200 hover:bg-gray-50"
                >
                  {/* Project name (fixed column) */}
                  <div className="sticky left-0 z-10 flex items-center flex-shrink-0 w-64 p-3 pl-1 bg-white border-r border-gray-200 min-w-64 group cursor-grab active:cursor-grabbing">
                    <div className="flex items-center justify-center mr-1 transition-all opacity-0 cursor-pointer text-zinc-700 group-hover:opacity-100">
                      <DragDotsIcon className="size-3" />
                    </div>
                    <div className="mr-2 text-xs font-medium line-clamp-1">
                      {project.title || 'Unknown Project'}
                    </div>

                    <div
                      onClick={() => handleRemoveProject(project.id)}
                      className="ml-auto transition-all opacity-0 top-1 right-1 group-hover:opacity-100 text-zinc-500"
                    >
                      <CrossIcon className="size-2.5" />
                    </div>
                  </div>

                  {/* Checkboxes for each person */}
                  <div className="flex">
                    {people.map((person) => (
                      <div
                        key={`${project.id}-${person.id}`}
                        className="flex items-center justify-center flex-shrink-0 p-3 border-r border-gray-200 w-36"
                      >
                        <Checkbox
                          checked={
                            relationshipDataRef.current?.[
                              project?.id
                            ]?.includes(person.id) || false
                          }
                          onCheckedChange={(checked) =>
                            handleCheckboxChange(project.id, person.id, checked)
                          }
                          aria-label={`Assign ${person.name} to ${project.title}`}
                        />
                      </div>
                    ))}
                    {/* Empty cell for Add People column */}
                    <div className="flex items-center justify-center flex-shrink-0 p-3 border-r border-gray-200 w-36">
                      {/* This cell is intentionally left empty */}
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {/* Add Project row */}
            <div className="flex w-full border-b border-gray-200 hover:bg-gray-50">
              <div className="sticky left-0 z-10 flex items-center flex-shrink-0 w-64 p-3 bg-white border-r border-gray-200 min-w-64">
                <button
                  className="flex items-center justify-start w-full gap-2 text-xs font-medium text-zinc-600"
                  aria-label="Add project to matrix"
                  type="button"
                  onClick={handleAddProjectClick}
                >
                  <PlusIcon className="text-zinc-600 size-4" />
                  <span>Add Project</span>
                </button>
              </div>

              {/* Empty cells for the add project row */}
              <div className="flex">
                {people.map((person) => (
                  <div
                    key={`add-project-${person.id}`}
                    className="flex-shrink-0 p-3 border-r border-gray-200 w-36"
                  />
                ))}
                {/* Empty cell for the last column */}
                <div className="flex-shrink-0 p-3 border-r border-gray-200 w-36" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add People Dialog */}
      <AddPeopleDialog
        isOpen={showAddPeopleDialog}
        onClose={() => {
          console.log('Closing dialog from onClose')
          setShowAddPeopleDialog(false)
        }}
        onAddPeople={handleAddPeople}
        existingPeople={people}
      />

      {/* Add Project Dialog */}
      <AddProjectDialog
        isOpen={showAddProjectDialog}
        onClose={() => setShowAddProjectDialog(false)}
        onAddProjects={handleAddProjects}
        existingProjects={projects}
      />
    </div>
  )
}

export default DraggableGrid

function PeopleCells({
  people,
  onPeopleOrderChange,
  onAddPeople,
  onRemovePeople
}) {
  const swapyRef = useRef(null)
  const containerRef = useRef(null)
  const [s3Obj, sets3Obj] = useState(null)
  const [peopleOrder, setPeopleOrder] = useState(people)

  console.log('peopleOrder', peopleOrder)
  useEffect(() => {
    async function initalizeData() {
      const s3 = await initalizeS3()
      sets3Obj(s3)
    }
    initalizeData()
  }, [])

  useEffect(() => {
    if (containerRef.current) {
      swapyRef.current = createSwapy(containerRef.current, {
        animation: 'dynamic',
        dragAxis: 'x',
        autoScrollOnDrag: true
      })

      swapyRef.current.onBeforeSwap((event) => {
        console.log('beforeSwap', event)
        return true
      })

      swapyRef.current.onSwapStart((event) => {
        console.log('start', event)
        // Make sure dragging works by ensuring the item can receive pointer events
        const item = event.item
        if (item) {
          item.style.pointerEvents = 'auto'
        }
      })

      swapyRef.current.onSwap((event) => {
        console.log('swap', event)
      })

      swapyRef.current.onSwapEnd((event) => {
        if (event.hasChanged) {
          onPeopleOrderChange(event.slotItemMap.asArray)
        }
      })
    }
    return () => {
      swapyRef.current?.destroy()
      console.log('swapy destroyed', people)
      setPeopleOrder(people)
    }
  }, [])

  const handleAddPeopleClick = (e) => {
    console.log(
      'Adding people clicked in PeopleCells with onAddPeople:',
      onAddPeople
    )
    try {
      onAddPeople()
      console.log('onAddPeople called successfully')
    } catch (error) {
      console.error('Error calling onAddPeople:', error)
    }
  }

  return (
    <div className="flex min-w-max" ref={containerRef}>
      {peopleOrder.map((person) => (
        <div
          className="relative flex items-center p-1 px-1.5 border-r w-36 slot top border-zinc-200 group pr-2"
          data-swapy-slot={person.id}
          key={person.id}
          style={{ pointerEvents: 'auto' }}
        >
          <div
            className="flex items-center justify-center w-full font-medium text-xxs item item-a"
            data-swapy-item={person.id}
            style={{ pointerEvents: 'auto' }}
          >
            <div className="flex items-center justify-center mr-1 transition-all opacity-0 cursor-pointer text-zinc-700 group-hover:opacity-100">
              <DragDotsIcon className="size-3" />
            </div>

            <ImageAvatarFallback
              name={person.name}
              profilePic={
                person.profile_picture ?? person.profile_picture_location
              }
              style={{
                height: '24px',
                minWidth: '24px',
                maxWidth: '24px',
                fontSize: '12px'
              }}
              openImage={() => {}}
              s3Obj={s3Obj}
            />
            <div className="ml-2 line-clamp-2">{person.name}</div>

            <div
              onClick={() => onRemovePeople(person.id)}
              className="ml-2 transition-all opacity-0 cursor-pointer top-1 right-1 group-hover:opacity-100 text-zinc-500"
            >
              <CrossIcon className="size-2.5" />
            </div>
          </div>
        </div>
      ))}

      <div className="flex items-center justify-center p-1 px-3 border-r w-36 slot top border-zinc-200">
        <button
          className="flex flex-col items-center justify-center w-full gap-1 font-medium text-zinc-600 text-xxs"
          aria-label="Add people to matrix"
          type="button"
          onClick={handleAddPeopleClick}
        >
          <PlusIcon className="text-zinc-600 size-4" />
          <span>Add People</span>
        </button>
      </div>
    </div>
  )
}
