import Button2 from '../../components/Button/Button2'
import {
  MaximizeIcon,
  ThreeDotsIcon,
  PencilIcon,
  LinkIcon,
  CopyIcon,
  CrossIcon,
  PinIcon
} from '../../components/Icons/Icons'
import Notebook from '../Notebook/Notebook'
import { Box, Dialog, Drawer, Grow, Menu, Tooltip } from '@mui/material'
import {
  ImageRender,
  TextLineLimiter,
  ImageAvatarFallback,
  TagFilter,
  ImageGallery
} from '../../components'
import SearchNew from '../ListFilter/SearchNew'
import React, { useEffect, useState } from 'react'
import { fetchPeople } from '../../store/People/Actions'
import { useDispatch, useSelector } from 'react-redux'
import DisplayTags from './DisplayTags'
import TagCenterUpdate from '../TagCenterUpdate'
import EditProfileModal from './EditProfileModal'
import mixpanelEvents from '../../config/mixpanelEvents'
import { toast } from 'react-toastify'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import {
  contentSearch,
  postCollectionData,
  changePeopleInfo
} from '../../store/api'
import AttachFilesUI from '../../components/AttachFilesUI/AttachFilesUI'
import ResourceFileView from '../ResourceFileView'
import { useStyles } from './styles'
import { checkUserRoleViewer } from '../../utils/User'
import FilterListIcon from '@mui/icons-material/FilterList'
import { cn } from '@/lib/utils'
import { checkExpiry } from '@/utils/AWS'
import _ from 'lodash'

const ResumeGrid = ({
  people,
  v2 = false,
  s3Obj,
  handleCloseProject,
  openImage,
  openPdf,
  handleDownloadCustomPdf,
  handleMove,
  handleMasterMappingDelete,
  showResumeEditor,
  isSuperUser,
  refresh,
  searchKey = '',
  pageType = 'people',
  highlightedData = '',
  setSelectedPerson = () => {}
}) => {
  const [editProfile, setEditProfile] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const [activeTab, setActiveTab] = useState(0)
  const [filteredResumes, setFilteredResumes] = useState([])
  const [notes, setNotes] = useState(people?.notes ?? [])
  const [showTagDrawer, setShowTagDrawer] = useState(false)
  const [basicInfo, setBasicInfo] = useState({
    name: people?.name,
    logo: people?.profile_picture ?? people?.profile_picture_location,
    domain: 'Domain',
    location: 'Location',
    industry: 'Industry',
    tags: people?.tags ?? [],
    employeeType: people?.properties?.employee_type?.value ?? 'unknown',
    emails: people?.properties?.email_ids?.value ?? [],
    extra: people?.enrichment_data?.data,
    files: people?.uploaded_files ?? []
  })
  const [files, setFiles] = useState(people?.uploaded_files ?? [])
  const [selectedScreenshots, setSelectedScreenshots] = useState([])
  console.log('people', filteredResumes)
  const [contentSearching, setContentSearching] = useState(false)

  const resumes = people?.resumes || people?.resume_list || []

  const [searchQuery, setSearchQuery] = useState('')
  const dispatch = useDispatch()
  const [selectedResume, setSelectedResume] = useState(null)
  const [editName, setEditName] = useState(null)
  const [openResumeFileViewer, setOpenResumeFileViewer] = useState(false)
  const [selectedResumeFile, setSelectedResumeFile] = useState(null)
  const [contentSearched, setContentSearched] = useState(false)
  const [loadingData, setLoadingData] = useState(false)
  const classes = useStyles()

  const isUserViewer = checkUserRoleViewer()

  const [fileExternsionOptions, setFileExternsionOptions] = useState([])
  const [domainUserNames, setDomainUserNames] = useState([])
  const [tagsOptions, setTagsOptions] = useState(null)
  const [selectedTags, setSelectedTags] = useState(null)

  const tagsCenterStateTagsAll = useSelector((state) => state?.tagCenter?.tags)
  const [tagsCenterStateTags, setTagsCenterStateTags] = useState({})

  const [documentTypeTags, setDocumentTypeTags] = useState([])
  const [isFiltersApplied, setIsFiltersApplied] = useState(false)

  const [tagFilteredDocumentIds, setTagFilteredDocumentIds] = useState([])

  const [sortOrder, setSortOrder] = useState({
    created_at: 'desc'
  })

  const domainUsers = useSelector(
    (state) => state.authenticate.user?.domain?.domain_users || []
  )
  const user_name = useSelector(
    (state) => state.authenticate.user?.domain?.user_name || ''
  )
  const user_id = useSelector(
    (state) => state.authenticate.user?.domain?.id || ''
  )

  const handleTagsValueChange = (key, value, mode) => {
    let selectedTagsClone = {}
    if (mode === 'value') {
      if (_.isEmpty(value)) {
        const temp = { ...selectedTags }
        delete temp[key]
        setSelectedTags(temp)
        selectedTagsClone = temp
      } else {
        if (selectedTags) {
          setSelectedTags({
            ...selectedTags,
            [key]: {
              ...selectedTags[key],
              values: value
            }
          })
          selectedTagsClone = {
            ...selectedTags,
            [key]: {
              ...selectedTags[key],
              values: value
            }
          }
        } else {
          setSelectedTags({
            [key]: {
              values: value
            }
          })
          selectedTagsClone = {
            [key]: {
              values: value
            }
          }
        }
      }
    }
    if (mode === 'condition') {
      if (selectedTags) {
        setSelectedTags({
          ...selectedTags,
          [key]: {
            ...selectedTags[key],
            condition: value
          }
        })
        selectedTagsClone = {
          ...selectedTags,
          [key]: {
            ...selectedTags[key],
            condition: value
          }
        }
      } else {
        setSelectedTags({
          [key]: {
            condition: value
          }
        })
        selectedTagsClone = {
          [key]: {
            condition: value
          }
        }
      }
    }

    handleChangeTags(selectedTagsClone)
  }

  const initFileOptions = () => {
    const fileTypes = [
      {
        value: 'proposal',
        label: 'Proposals'
      },
      {
        value: 'images',
        label: 'Images'
      },
      {
        value: 'videos',
        label: 'Videos'
      },
      {
        value: 'proposal_images',
        label: 'Proposal Images'
      },
      {
        value: 'document',
        label: 'Documents'
      }
    ]
    const fileExtensions = [
      {
        value: 'jpeg',
        label: 'JPEG'
      },
      {
        value: 'png',
        label: 'PNG'
      },
      {
        value: 'mp4',
        label: 'MP4'
      },
      {
        value: 'jpg',
        label: 'JPG'
      },
      {
        value: 'pdf',
        label: 'PDF'
      },
      {
        value: 'doc, docx',
        label: 'DOC'
      },
      {
        value: 'ppt, pptx',
        label: 'PPT'
      }
    ]
    const userNames = domainUsers.map((user) => {
      return {
        value: user.id,
        label: user.user_name,
        email: user.email
      }
    })

    userNames.push({
      value: user_id,
      label: user_name
    })

    setFileExternsionOptions(fileExtensions)
    setDomainUserNames(userNames)
  }

  useEffect(() => {
    if (loadingData) {
      initFileOptions()
    }
  }, [loadingData])

  useEffect(() => {
    if (
      tagsCenterStateTagsAll &&
      !_.isEqual(
        Object.keys(tagsCenterStateTagsAll),
        Object.keys(tagsCenterStateTags)
      )
    ) {
      const filteredData = Object.keys(tagsCenterStateTagsAll || {}).reduce(
        (acc, key) => {
          const { key_type } = tagsCenterStateTagsAll[key] || {}
          if (key_type && key_type?.includes('document')) {
            acc[key] = tagsCenterStateTagsAll[key]
          }
          return acc
        },
        {}
      )
      setTagsCenterStateTags({ ...filteredData })
    }
  }, [tagsCenterStateTagsAll])

  useEffect(() => {
    const tagsValues = {}
    Object.keys(tagsCenterStateTags || {}).forEach((key, index) => {
      const {
        data = [],
        type,
        value_type,
        ...rest
      } = tagsCenterStateTags[key] || {}
      if (value_type?.includes('document_type')) {
        setDocumentTypeTags(data)
      }
      data.forEach((element) => {
        if (element.value) {
          if (tagsValues[key]?.values) {
            tagsValues[key].values.push({
              value: element.id,
              label: element.value
            })
          } else {
            if (!tagsValues[key]) tagsValues[key] = {}
            tagsValues[key].values = [
              {
                value: element.id,
                label: element.value
              }
            ]
          }
        }
        tagsValues[key] = {
          ...tagsValues[key],
          type,
          ...rest
        }
      })
    })

    setTagsOptions(tagsValues)
  }, [tagsCenterStateTags])

  const [controllers, setControllers] = useState([])

  const createAbortController = () => {
    const abortController = new AbortController()
    setControllers((prevControllers) => [...prevControllers, abortController])
    return abortController
  }

  const handleChangeTags = async (selectedTagsClone) => {
    const tag_values = []
    const abortController = createAbortController()
    selectedTagsClone &&
      Object.keys(selectedTagsClone).forEach((key) => {
        if (key === 'file_type') {
          const { condition, values = [] } = selectedTagsClone[key] || {}
          const value = values.map((item) => item.value)
          file_type = { condition: condition || 'is', values: value }
        } else if (key === 'file_extension') {
          const { condition, values = [] } = selectedTagsClone[key] || {}
          const fileExts = []
          values.forEach((item) => {
            if (item.value?.includes(',')) {
              fileExts.push(...item.value?.split(',').map((str) => str.trim()))
            } else {
              fileExts.push(item.value)
            }
          })
          file_extension = { condition: condition || 'is', values: fileExts }
        } else if (key === 'hidden_from_search') {
          const { condition, values = [] } = selectedTagsClone[key] || {}
          const value = values.map((item) => item.value)
          hidden_manually = {
            condition: condition || 'is',
            values: value?.[0]
          }
        } else {
          const tags = []
          let { condition, values = [] } = selectedTagsClone[key] || {}
          values = values || []
          values.forEach((item) => {
            if (item?.type?.includes('date')) {
              tag_dates.push({ ...item, condition: condition || 'is' })
            } else {
              tags.push(item.value)
            }
          })
          if (tags.length > 0) {
            tag_values.push({
              key,
              condition: condition || 'is',
              values: tags
            })
          }
        }
      })

    const knowledge_document_ids_to_filter = filteredResumes.map(
      (item) => item.proposal_id
    )

    const req = {
      page_num: 1,
      page_size: 15,
      type: 'all',
      keyword: '',
      tags: tag_values,
      tag_dates: [],
      file_type: null,
      file_extension: null,
      hidden_manually: null,
      sort_order: sortOrder,
      knowledge_document_ids_to_filter,
      is_knowledge_profile_filter: true
    }
    setContentSearching(true)
    if (tag_values.length > 0) {
      const res = await postCollectionData(req, {
        signal: abortController.signal
      })
      if (res.status === 200) {
        const { data } = res.data
        const newDocumentIds = data.map((item) => item.id)
        setTagFilteredDocumentIds(newDocumentIds)
        setIsFiltersApplied(true)
      }
    } else {
      setIsFiltersApplied(false)
      setTagFilteredDocumentIds([])
    }
    setContentSearching(false)
  }

  useEffect(() => {
    if (tagFilteredDocumentIds.length > 0) {
      const filtered = resumes.filter((resume) => {
        return tagFilteredDocumentIds.includes(resume.proposal_id)
      })
      setFilteredResumes(filtered)
    } else {
      if (isFiltersApplied) {
        setFilteredResumes([])
      } else {
        setFilteredResumes(resumes)
      }
    }
  }, [tagFilteredDocumentIds])

  useEffect(() => {
    setFilteredResumes(resumes)
    const { uploaded_files = [] } = people || {}
    setFiles(uploaded_files)
  }, [people])

  useEffect(() => {
    if (searchQuery === '') {
      if (tagFilteredDocumentIds.length > 0) {
        const tagFilteredResumes = resumes?.filter((resume) => {
          return tagFilteredDocumentIds?.includes(resume.proposal_id)
        })
        setFilteredResumes(tagFilteredResumes)
      } else {
        setFilteredResumes(resumes)
      }
      setContentSearched(false)
      return
    }

    let timeouttime = 1500
    if (highlightedData !== '') {
      timeouttime = 0
    }

    const timeout = setTimeout(() => {
      setContentSearching(true)
      trackEvent(
        mixpanelEvents.PEOPLE_DOCUMENT_CONTENT_SEARCHED,
        'SUCCESS',
        {},
        {
          people_id: people.id,
          search_query: searchQuery
        }
      )
      if (highlightedData !== '') {
        const foundList = highlightedData?.map((item) => item.id)
        const foundListDict = {}
        highlightedData.forEach((item) => {
          foundListDict[item.id] = item.text.resume_data
        })
        setContentSearching(false)
        const filtered = resumes.filter((resume) => {
          return foundList.includes(resume.id)
        })
        let newFilteredResumes = filtered.map((resume) => {
          return {
            ...resume,
            searchedContent: foundListDict[resume.id]
          }
        })
        tagFilteredDocumentIds.length > 0 &&
          (newFilteredResumes = newFilteredResumes.filter((resume) => {
            return tagFilteredDocumentIds.includes(resume.proposal_id)
          }))

        setFilteredResumes(newFilteredResumes)
        setContentSearched(true)
      } else {
        contentSearch({
          group_id: people?.id,
          search_keyword: searchQuery,
          search_type: 'people'
        })
          .then((res) => {
            const resData = res.data.data
            const foundList = resData.map((item) => item.id)
            const foundListDict = {}
            resData.forEach((item) => {
              foundListDict[item.id] = item.text.resume_data
            })
            setContentSearching(false)
            const filtered = resumes.filter((resume) => {
              return foundList.includes(resume.id)
            })
            let newFilteredResumes = filtered.map((resume) => {
              return {
                ...resume,
                searchedContent: foundListDict[resume.id]
              }
            })
            tagFilteredDocumentIds.length > 0 &&
              (newFilteredResumes = newFilteredResumes.filter((resume) => {
                return tagFilteredDocumentIds.includes(resume.proposal_id)
              }))
            setFilteredResumes(newFilteredResumes)
            setContentSearched(true)
          })
          .catch((err) => {
            console.log(err)
            setContentSearching(false)
          })
      }
    }, timeouttime)

    return () => clearTimeout(timeout)
  }, [searchQuery])

  const handleSearch = (value) => {
    setSearchQuery(value)
    // const filtered = resumes.filter((resume) => {
    //   return resume.proposal_name?.toLowerCase().includes(value.toLowerCase())
    // })
    // setFilteredResumes(filtered)
  }

  useEffect(() => {
    if (searchKey) {
      setContentSearching(true)
      handleSearch(searchKey)
    }
  }, [searchKey])

  const handleClick = (event, resume) => {
    setSelectedResume(resume)
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleNameChange = (newName) => {
    setBasicInfo({
      name: newName,
      ...basicInfo
    })
  }

  const refreshData = () => {
    setLoadingData(true)
    const req = {
      keyword: '',
      page_num: 1,
      page_size: 10,
      tags: [],
      tag_dates: [],
      master_people_id: people?.id,
      isSingleResume: true
    }

    const callback = (payload) => {
      const { peopleList } = payload

      if (peopleList.length > 0) {
        console.log('peopleList 2', peopleList)
        const person = peopleList[0]
        setNotes(peopleList[0]?.notes ?? [])
        setBasicInfo({
          name: person?.name,
          logo: person?.profile_picture ?? person?.profile_picture_location,
          domain: 'Domain',
          location: 'Location',
          industry: 'Industry',
          tags: person?.tags ?? [],
          employeeType: person?.properties?.employee_type?.value ?? 'unknown',
          emails: person?.properties?.email_ids?.value ?? [],
          files: person?.uploaded_files ?? []
        })
      }
      setLoadingData(false)
    }

    dispatch(fetchPeople(req, {}, callback))
  }

  useEffect(() => {
    if (activeTab === 1) {
      trackEvent(
        mixpanelEvents.PEOPLE_NOTES_TAB_OPENED,
        'SUCCESS',
        {},
        {
          people_id: people.id
        }
      )
    }

    if (activeTab === 2) {
      trackEvent(
        mixpanelEvents.PROJECT_FILE_TAB_OPENED,
        'SUCCESS',
        {},
        {
          people_id: people.id
        }
      )
    }
  }, [activeTab])

  const onResumeFileDialogClose = () => {
    setOpenResumeFileViewer(false)
    setSelectedResumeFile(null)
  }

  const openResumeFileDialog = (proposal_id, page_num) => {
    setSelectedResumeFile({
      id: proposal_id,
      other_data: {
        page_number: page_num
      },
      file_type: 'resume'
    })
    setOpenResumeFileViewer(true)
  }

  const handleCopy = (content) => {
    try {
      navigator.clipboard.writeText(content.replace(/<[^>]*>?/gm, ''))
      toast.success('Copied to clipboard')
    } catch (err) {
      console.log('err', err)
    }
  }

  const handlePinResume = async (resume_id) => {
    if (isUserViewer) return
    const result = filteredResumes
      .map((resume) => {
        if (resume.id === resume_id) {
          resume.is_resume_pinned = !resume.is_resume_pinned
        }
        return resume
      })
      .sort((a, b) => {
        if (b.is_resume_pinned !== a.is_resume_pinned) {
          return b.is_resume_pinned - a.is_resume_pinned
        }
        const dateA = a.submission_date
          ? new Date(a.submission_date)
          : new Date(0)
        const dateB = b.submission_date
          ? new Date(b.submission_date)
          : new Date(0)
        return dateB - dateA
      })
    setSelectedPerson({
      ...people,
      resumes: result
    })
    setFilteredResumes(result)

    const data = {
      id: people.id,
      es_reindex_required: false,
      pinned_resume_id: resume_id
    }

    const res = await changePeopleInfo(data)

    if (res.status === 200) {
      trackEvent(
        mixpanelEvents.PEOPLE_RESUME_PINNED,
        'SUCCESS',
        {},
        {
          people_id: people.id,
          resume_id
        }
      )
    } else {
    }
  }

  const handleOpenImage = async (payload = []) => {
    console.log('payload', payload)
    const url = _.cloneDeep(payload)
    if (_.isArray(url)) {
      await Promise.all(
        url.map(async (img, index) => {
          const signedSrc = await checkExpiry(img.src, s3Obj)
          url[index].src = signedSrc
        })
      )
      setSelectedScreenshots(url)
    } else {
      const signedSrc = await checkExpiry(url, s3Obj)
      setSelectedScreenshots([{ src: signedSrc }])
    }

    trackEvent(
      mixpanelEvents.PEOPLE_RESUME_SCREENSHOT_VIEWED,
      'SUCCESS',
      {},
      { proposal_id: people?.resumes[selectedResume]?.proposal_id }
    )
  }

  if (v2) {
    return (
      <div className="p-4 pr-0 bg-white ">
        <div className="flex items-center gap-2">
          <div className="flex flex-col w-full">
            <div className="flex items-center w-full gap-2">
              <div className="w-full">
                <div className="flex items-center gap-2">
                  {basicInfo.logo ? (
                    <ImageAvatarFallback
                      name={basicInfo.name}
                      profilePic={basicInfo.logo}
                      s3Obj={s3Obj}
                      style={{
                        width: '65px',
                        height: '65px',
                        objectFit: 'cover',
                        borderRadius: '10px',
                        backgroundColor: 'var(--grey-400)',
                        border: '1px solid var(--grey-200)'
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        width: '50px',
                        height: '50px',
                        padding: '6px',
                        borderRadius: '10px',
                        border: '1px solid var(--grey-200)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'var(--grey-100)'
                      }}
                    >
                      {basicInfo.name?.split(' ')?.[0]?.[0]}
                      {basicInfo.name?.split(' ')?.[1]?.[0]
                        ? basicInfo.name?.split(' ')?.[1]?.[0]
                        : basicInfo.name?.split(' ')?.[0]?.[1]?.toUpperCase()}
                    </div>
                  )}
                  <p
                    className="gap-1 m-0 text-lg font-medium leading-5 text-capitalize"
                    style={{
                      maxHeight: '40px',
                      // width: '80%',
                      overflow: 'hidden'
                    }}
                  >
                    {basicInfo.name}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="flex mt-3 h-[calc(100% - 200px)]">
          <p
            className="p-1 px-3 m-0 text-sm font-medium cursor-pointer text-grey-700"
            onClick={() => setActiveTab(0)}
            style={{
              borderBottom: activeTab === 0 && '2px solid var(--grey-700)'
            }}
          >
            Resumes
          </p>
          <p
            className="p-1 px-3 m-0 text-sm font-medium cursor-pointer text-grey-700"
            onClick={() => setActiveTab(1)}
            style={{
              borderBottom: activeTab === 1 && '2px solid var(--grey-700)'
            }}
          >
            Notes
          </p>
          <p
            className="p-1 px-3 m-0 text-sm font-medium cursor-pointer text-grey-700"
            onClick={() => setActiveTab(2)}
            style={{
              borderBottom: activeTab === 2 && '2px solid var(--grey-700)'
            }}
          >
            Files
          </p>
        </div> */}
        <div className="">
          <div
            className="pt-4 bg-white"
            // style={{
            //   height: 'calc(100% - 200px)'
            // }}
          >
            <div className="rounded-lg">
              {activeTab === 0 && (
                <div
                  style={
                    {
                      // padding: '10px 20px'
                    }
                  }
                >
                  {contentSearching ? (
                    <div className="grid place-content-center">
                      Searching...
                    </div>
                  ) : (
                    <div className="">
                      {filteredResumes.length === 0 && (
                        <div className="flex items-center justify-center h-40">
                          <p className="text-grey-500">No resumes found</p>
                        </div>
                      )}
                      <div className={`grid gap-4 grid-cols-3`}>
                        {filteredResumes
                          ?.sort(
                            (a, b) => b.is_resume_pinned - a.is_resume_pinned
                          )
                          .map((resume, index) => {
                            const {
                              proposal_id,
                              thumbnail_url = '',
                              screenshots = [],
                              page_number = 1,
                              proposal_name = '',
                              version = '',
                              searchedContent = '',
                              is_resume_pinned
                            } = resume
                            const coverImage = _.isEmpty(screenshots)
                              ? thumbnail_url
                              : screenshots[0]?.src
                            const imagePayload = _.isEmpty(screenshots)
                              ? thumbnail_url
                              : screenshots
                            const displayName = proposal_name || proposal_id
                            console.log(
                              're-rendering',
                              coverImage,
                              imagePayload
                            )

                            return (
                              proposal_id && (
                                <div
                                  style={{
                                    position: 'relative',
                                    maxWidth: contentSearched ? '100%' : '170px'
                                  }}
                                  className="group"
                                >
                                  <Box
                                    key={index}
                                    className="parent-card"
                                    sx={{
                                      display: 'flex',
                                      flexDirection: contentSearched
                                        ? 'row'
                                        : 'column',
                                      justifyContent: 'space-between',
                                      border: '1px solid var(--grey-200)',
                                      overflow: 'hidden',
                                      borderRadius: '10px',
                                      '&:hover img': {
                                        opacity: '0.5'
                                      },
                                      '&:hover #overlayMenu': {
                                        opacity: '0.8 !important'
                                      }
                                    }}
                                  >
                                    <Box
                                      id={'project_' + index}
                                      key={'project_' + index}
                                      style={{
                                        padding: '10px',
                                        position: 'relative'
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          justifyContent: 'center',
                                          width: '100%',
                                          display: 'flex',
                                          cursor: 'pointer'
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: 'flex',
                                            alignItems: 'center'
                                          }}
                                        >
                                          <ImageRender
                                            style={{
                                              height: '100%',
                                              width: '100%',
                                              minWidth: contentSearched
                                                ? '120px'
                                                : '180px',
                                              maxWidth: contentSearched
                                                ? '120px'
                                                : '200px',
                                              minHeight: contentSearched
                                                ? '140px'
                                                : '220px',
                                              maxHeight: contentSearched
                                                ? '140px'
                                                : '220px',
                                              objectFit: 'contain',
                                              overflow: 'hidden',
                                              transition: 'opacity 0.5s ease'
                                            }}
                                            src={coverImage}
                                            s3Obj={s3Obj}
                                            effect="blur"
                                            overlay={
                                              <Box
                                                id="overlayMenu"
                                                style={{
                                                  position: 'absolute',
                                                  top: '0%',
                                                  right: '0%',
                                                  opacity: 0,
                                                  backgroundColor:
                                                    'var(--grey-700)',
                                                  padding: '8px',
                                                  width: '100%',
                                                  height: '100%',
                                                  boxSizing: 'border-box',
                                                  zIndex: 1000,
                                                  transition:
                                                    'opacity 0.3s ease'
                                                }}
                                              >
                                                <div className="relative h-full">
                                                  {coverImage && (
                                                    <div
                                                      className="absolute"
                                                      style={{
                                                        top: '45%',
                                                        left: '45%'
                                                      }}
                                                      onClick={() => {
                                                        handleOpenImage(
                                                          imagePayload
                                                        )
                                                      }}
                                                    >
                                                      <MaximizeIcon className="size-6 text-grey-100" />
                                                    </div>
                                                  )}
                                                </div>
                                              </Box>
                                            }
                                            fallback={
                                              <>
                                                <Box
                                                  sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    wordBreak: 'break-word'
                                                  }}
                                                >
                                                  <Box
                                                    sx={{
                                                      fontWeight: '600',
                                                      fontSize: '16px',
                                                      overflow: 'hidden',
                                                      textOverflow: 'ellipsis',
                                                      '-webkit-line-clamp': 2,
                                                      display: '-webkit-box',
                                                      '-webkit-box-orient':
                                                        'vertical'
                                                    }}
                                                  >
                                                    {displayName}
                                                  </Box>
                                                </Box>
                                              </>
                                            }
                                          />
                                        </Box>
                                      </Box>
                                    </Box>
                                    <div
                                      className="flex items-center justify-between p-3 bg-grey-100"
                                      style={{
                                        ...(contentSearched && {
                                          width: 'calc(100% - 160px)'
                                        })
                                      }}
                                    >
                                      {proposal_name ? (
                                        <Tooltip title={proposal_name}>
                                          <p
                                            className="m-0 text-xs font-medium cursor-pointer"
                                            style={{
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis',
                                              whiteSpace: 'nowrap',
                                              height: '100%'
                                            }}
                                            onClick={() => {
                                              searchQuery === ''
                                                ? openPdf(
                                                    proposal_id,
                                                    page_number
                                                  )
                                                : openResumeFileDialog(
                                                    proposal_id,
                                                    page_number
                                                  )
                                            }}
                                          >
                                            {contentSearched ? (
                                              <div className="flex flex-col h-full align-start">
                                                <div className="flex items-center gap-1 mb-2 text-sm text-grey-800">
                                                  {proposal_name}
                                                  {page_number && (
                                                    <span className="ml-2 font-medium">
                                                      - Page {page_number}
                                                    </span>
                                                  )}{' '}
                                                </div>
                                                <TextLineLimiter
                                                  content={
                                                    <span
                                                      className="text-grey-500"
                                                      style={{
                                                        whiteSpace: 'pre-wrap'
                                                      }}
                                                      dangerouslySetInnerHTML={{
                                                        __html: searchedContent
                                                      }}
                                                    />
                                                  }
                                                  limit={2}
                                                  startAt="<mark>"
                                                  isStartAtHTML="mark"
                                                  styleProps={{
                                                    whiteSpace: 'pre-wrap'
                                                  }}
                                                />
                                              </div>
                                            ) : (
                                              <div className="text-grey-500">
                                                {proposal_name}
                                              </div>
                                            )}
                                          </p>
                                        </Tooltip>
                                      ) : (
                                        <p className="m-0 text-sm font-medium">
                                          Untitled Project
                                        </p>
                                      )}

                                      <div className="relative flex flex-col justify-between w-16 h-full card-options">
                                        <div className="flex justify-end rounded text-grey-700">
                                          <span
                                            onClick={(e) =>
                                              handleClick(e, resume)
                                            }
                                            className="grid cursor-pointer place-content-center"
                                          >
                                            <ThreeDotsIcon
                                              className="size-5"
                                              strokeWidth={2}
                                            />
                                          </span>
                                          <Menu
                                            elevation={2}
                                            TransitionComponent={
                                              Grow
                                              // style={{
                                              //   // transformOrigin from center
                                              //   transformOrigin: '10px 10px'
                                              // }}
                                            }
                                            id="basic-menu"
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleCloseMenu}
                                            MenuListProps={{
                                              'aria-labelledby': 'basic-button'
                                            }}
                                            className="p-1"
                                          >
                                            <Button2
                                              noOutline
                                              secondary
                                              style={{
                                                width: '100%'
                                              }}
                                              onClick={() =>
                                                handleDownloadCustomPdf(
                                                  selectedResume.proposal_id,
                                                  selectedResume.page_number -
                                                    1,
                                                  coverImage
                                                )
                                              }
                                            >
                                              Download Resume
                                            </Button2>
                                          </Menu>
                                        </div>
                                        {contentSearched && (
                                          <div className="flex justify-end mt-2">
                                            <Tooltip title="Copy">
                                              <CopyIcon
                                                className="cursor-pointer size-3"
                                                onClick={() => {
                                                  handleCopy(searchedContent)
                                                }}
                                              />
                                            </Tooltip>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </Box>
                                </div>
                              )
                            )
                          })}
                      </div>
                    </div>
                  )}
                </div>
              )}

              {activeTab === 1 && (
                <Notebook
                  notesData={notes}
                  refresh={() => {
                    refreshData()
                  }}
                  source={{
                    id: people?.id,
                    type: 'resume',
                    name: people?.name
                  }}
                />
              )}
              {activeTab === 2 && (
                <AttachFilesUI
                  loading={loadingData}
                  files={files}
                  setFiles={setFiles}
                  profileId={people.id}
                  source="people"
                  isUserViewer={isUserViewer}
                />
              )}
            </div>
          </div>
        </div>

        {selectedScreenshots.length > 0 && (
          <ImageGallery
            images={selectedScreenshots}
            onClose={() => setSelectedScreenshots([])}
            s3Obj={s3Obj}
          />
        )}
      </div>
    )
  }

  return (
    <div className="w-full h-full">
      <div className="h-full">
        <div
          style={{
            width: '70%',
            margin: 'auto',
            padding: '20px',
            paddingBottom: '0px'
          }}
        >
          {pageType === 'people' && (
            <div className="flex items-center gap-2 mb-3 text-xxs text-grey-500">
              <span className="cursor-pointer" onClick={handleCloseProject}>
                People
              </span>{' '}
              <span> {'>'} </span>{' '}
              <span className="text-grey-700 text-capitalize">
                {basicInfo.name}
              </span>
            </div>
          )}
          <div className="flex flex-col">
            <div className="flex items-center gap-2">
              {basicInfo.logo ? (
                <ImageAvatarFallback
                  name={basicInfo.name}
                  profilePic={basicInfo.logo}
                  s3Obj={s3Obj}
                  style={{
                    width: '65px',
                    height: '65px',
                    objectFit: 'cover',
                    borderRadius: '10px',
                    backgroundColor: 'var(--grey-400)',
                    border: '1px solid var(--grey-200)'
                  }}
                />
              ) : (
                <div
                  style={{
                    width: '50px',
                    height: '50px',
                    padding: '6px',
                    borderRadius: '10px',
                    border: '1px solid var(--grey-200)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'var(--grey-100)'
                  }}
                >
                  {basicInfo.name?.split(' ')?.[0]?.[0]}
                  {basicInfo.name?.split(' ')?.[1]?.[0]
                    ? basicInfo.name?.split(' ')?.[1]?.[0]
                    : basicInfo.name?.split(' ')?.[0]?.[1]?.toUpperCase()}
                </div>
              )}

              <div className="">
                <p className="flex items-end gap-1 m-0 text-lg font-medium leading-5 text-capitalize parent-card">
                  {basicInfo.name}{' '}
                  {basicInfo.employeeType !== 'unknown' && (
                    <span className="px-2 rounded-full text-xxs text-grey-600 bg-grey-100">
                      {basicInfo.employeeType}
                    </span>
                  )}
                  {basicInfo?.extra?.linkedin_url && (
                    <span
                      className="m-0 ml-1"
                      onClick={() => {
                        window.open(basicInfo?.extra?.linkedin_url, '_blank')
                      }}
                    >
                      <LinkIcon className="size-3 text-grey-500" />
                    </span>
                  )}
                </p>

                <div className="flex items-center gap-1 mb-2">
                  {basicInfo?.extra?.city && (
                    <p className="flex items-center gap-1 m-0 text-xxs">
                      {basicInfo.extra.city}
                      <div className="rounded-full size-1 bg-grey-300"></div>
                    </p>
                  )}

                  {basicInfo?.emails?.map((mail, i) => {
                    return (
                      <p
                        className="flex items-center gap-1 m-0 text-xxs"
                        key={mail}
                      >
                        {mail}
                        {basicInfo.emails.length - 1 !== i && (
                          <div className="rounded-full size-1 bg-grey-300"></div>
                        )}
                      </p>
                    )
                  })}
                </div>

                <DisplayTags
                  tags={basicInfo.tags}
                  setShowTagDrawer={setShowTagDrawer}
                />
              </div>

              <div className="flex items-center gap-2 ml-auto">
                {isSuperUser && pageType === 'people' && (
                  <Button2 onClick={showResumeEditor} secondary noOutline>
                    Resume Editor
                  </Button2>
                )}

                {pageType === 'people' && !isUserViewer && (
                  <Button2 onClick={() => setEditProfile(true)} secondary>
                    <PencilIcon className="mr-1 size-3" /> Edit Profile
                  </Button2>
                )}
              </div>
            </div>
          </div>
          <div className="flex mt-3">
            <p
              className="p-1 px-3 m-0 text-sm font-medium cursor-pointer text-grey-700"
              onClick={() => setActiveTab(0)}
              style={{
                borderBottom: activeTab === 0 && '2px solid var(--grey-700)'
              }}
            >
              Resumes
            </p>
            <p
              className="p-1 px-3 m-0 text-sm font-medium cursor-pointer text-grey-700"
              onClick={() => {
                setActiveTab(1)
                refreshData()
              }}
              style={{
                borderBottom: activeTab === 1 && '2px solid var(--grey-700)'
              }}
            >
              Notes
            </p>
            <p
              className="p-1 px-3 m-0 text-sm font-medium cursor-pointer text-grey-700"
              onClick={() => {
                setActiveTab(2)
                refreshData()
              }}
              style={{
                borderBottom: activeTab === 2 && '2px solid var(--grey-700)'
              }}
            >
              Files
            </p>
          </div>
        </div>

        <div
          className="p-4 bg-grey-100"
          style={{
            height: 'calc(100% - 200px)'
          }}
        >
          <div
            className="rounded-lg"
            style={{
              width: '70%',
              margin: 'auto',
              height: 'calc(100% - 20px)',
              overflowY: 'auto',
              backgroundColor: 'white',
              padding: '0 20px'
            }}
          >
            {activeTab === 0 && (
              <div
                style={{
                  padding: '10px 20px'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: '6px',
                    padding: '8px 0px',
                    marginBottom: '5px'
                  }}
                >
                  <div>
                    {selectedTags && Object.keys(selectedTags).length > 0 && (
                      // <Box>
                      <TagFilter
                        tags={{
                          // file_type: {
                          //   values: fileTypeOptions,
                          //   type: 'default',
                          //   editable: false
                          // },
                          file_extension: {
                            values: fileExternsionOptions,
                            type: 'default',
                            editable: false,
                            label: 'Extension'
                          },
                          created_by: {
                            values: domainUserNames,
                            type: 'default',
                            editable: false
                          },
                          created_on: {
                            type: 'multidate',
                            editable: false
                          },
                          hidden_from_search: {
                            values: [
                              { label: 'True', value: true },
                              { label: 'False', value: false }
                            ],
                            type: 'defaultsingle',
                            editable: false
                          },
                          ...tagsOptions
                        }}
                        showButton={false}
                        showTags={true}
                        disabled={contentSearching}
                        selectedTags={selectedTags}
                        onChange={handleTagsValueChange}
                        clearFilter={<></>}
                      />
                      // </Box>
                    )}
                  </div>
                  <div className="flex items-center gap-2">
                    <TagFilter
                      tags={{
                        file_extension: {
                          values: fileExternsionOptions,
                          type: 'default',
                          editable: false,
                          label: 'Extension'
                        },
                        created_by: {
                          values: domainUserNames,
                          type: 'default',
                          editable: false
                        },
                        created_on: {
                          type: 'multidate',
                          editable: false
                        },
                        hidden_from_search: {
                          values: [
                            { label: 'True', value: true },
                            { label: 'False', value: false }
                          ],
                          type: 'defaultsingle',
                          editable: false
                        },
                        ...tagsOptions
                      }}
                      showButton={true}
                      showTags={false}
                      disabled={contentSearching}
                      selectedTags={selectedTags}
                      onChange={handleTagsValueChange}
                      clearFilter={
                        isFiltersApplied && (
                          <Button2
                            secondary
                            onClick={() => {
                              if (!contentSearching) {
                                setSelectedTags(null)
                                setIsFiltersApplied(false)
                                setTagFilteredDocumentIds([])
                              }
                            }}
                          >
                            <CrossIcon className="size-4 text-grey-500 " />
                            clear filter
                          </Button2>
                        )
                      }
                      filterIcon={
                        !isFiltersApplied && (
                          <Button2
                            secondary
                            noOutline
                            disabled={contentSearching}
                            style={{
                              padding: '6px 10px'
                            }}
                          >
                            <FilterListIcon
                              style={{
                                height: '16px',
                                width: '16px'
                              }}
                            />{' '}
                            Filter
                          </Button2>
                        )
                      }
                    />
                    <SearchNew
                      value={searchQuery}
                      onChange={(value) => {
                        handleSearch(value)
                      }}
                      placeholder="Search Docs"
                      onClear={() => {
                        handleSearch('')
                        setFilteredResumes(resumes)
                      }}
                    />
                  </div>
                </div>
                {contentSearching ? (
                  <div className="grid place-content-center">Searching...</div>
                ) : (
                  <div className="">
                    {filteredResumes.length === 0 && (
                      <div className="flex items-center justify-center h-40">
                        <p className="text-grey-500">No resumes found</p>
                      </div>
                    )}
                    <div
                      className={`grid gap-4 ${
                        !contentSearched ? 'grid-col-5' : ''
                      }`}
                    >
                      {filteredResumes
                        ?.sort(
                          (a, b) => b.is_resume_pinned - a.is_resume_pinned
                        )
                        .map((resume, index) => {
                          const {
                            proposal_id,
                            thumbnail_url = '',
                            screenshots = [],
                            page_number = 1,
                            proposal_name = '',
                            version = '',
                            searchedContent = '',
                            is_resume_pinned
                          } = resume
                          const coverImage = _.isEmpty(screenshots)
                            ? thumbnail_url
                            : screenshots[0]?.src
                          const imagePayload = _.isEmpty(screenshots)
                            ? thumbnail_url
                            : screenshots
                          const displayName = proposal_name || proposal_id

                          return (
                            proposal_id && (
                              <div
                                style={{
                                  position: 'relative',
                                  maxWidth: contentSearched ? '100%' : '170px'
                                }}
                                className="group"
                              >
                                <div
                                  style={{
                                    position: 'absolute',
                                    borderRadius: '50%',
                                    width: 28,
                                    height: 28,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    zIndex: 1001,
                                    left: 'calc(100% - 16px)',
                                    bottom: 'calc(100% - 16px)'
                                  }}
                                  className={cn(
                                    'border border-zinc-200 shadow-md',
                                    isUserViewer
                                      ? 'invisible'
                                      : 'group-hover:visible',
                                    is_resume_pinned
                                      ? 'visible bg-zinc-300'
                                      : 'invisible bg-white'
                                  )}
                                >
                                  <Tooltip
                                    title={
                                      <Box
                                        className={cn(
                                          'flex items-center gap-1 align-center'
                                        )}
                                      >
                                        {!isUserViewer &&
                                          (is_resume_pinned
                                            ? 'Unpin this project'
                                            : 'Pin this project to top')}
                                      </Box>
                                    }
                                  >
                                    <div
                                      className="flex pointer"
                                      onClick={() => handlePinResume(resume.id)}
                                    >
                                      <PinIcon className="size-4 text-grey-700" />
                                    </div>
                                  </Tooltip>
                                </div>
                                <Box
                                  key={index}
                                  className="parent-card"
                                  sx={{
                                    display: 'flex',
                                    flexDirection: contentSearched
                                      ? 'row'
                                      : 'column',
                                    justifyContent: 'space-between',
                                    border: '1px solid var(--grey-200)',
                                    overflow: 'hidden',
                                    borderRadius: '10px',
                                    '&:hover img': {
                                      opacity: '0.5'
                                    },
                                    '&:hover #overlayMenu': {
                                      opacity: '0.8 !important'
                                    }
                                  }}
                                >
                                  <Box
                                    id={'project_' + index}
                                    key={'project_' + index}
                                    style={{
                                      padding: '10px',
                                      position: 'relative'
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        justifyContent: 'center',
                                        width: '100%',
                                        display: 'flex',
                                        cursor: 'pointer'
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <ImageRender
                                          style={{
                                            height: '100%',
                                            width: '100%',
                                            minWidth: contentSearched
                                              ? '120px'
                                              : '180px',
                                            maxWidth: contentSearched
                                              ? '120px'
                                              : '200px',
                                            minHeight: contentSearched
                                              ? '140px'
                                              : '220px',
                                            maxHeight: contentSearched
                                              ? '140px'
                                              : '220px',
                                            objectFit: 'contain',
                                            overflow: 'hidden',
                                            transition: 'opacity 0.5s ease'
                                          }}
                                          src={coverImage}
                                          s3Obj={s3Obj}
                                          effect="blur"
                                          overlay={
                                            <Box
                                              id="overlayMenu"
                                              style={{
                                                position: 'absolute',
                                                top: '0%',
                                                right: '0%',
                                                opacity: 0,
                                                backgroundColor:
                                                  'var(--grey-700)',
                                                padding: '8px',
                                                width: '100%',
                                                height: '100%',
                                                boxSizing: 'border-box',
                                                zIndex: 1000,
                                                transition: 'opacity 0.3s ease'
                                              }}
                                            >
                                              <div className="relative h-full">
                                                {coverImage && (
                                                  <div
                                                    className="absolute"
                                                    style={{
                                                      top: '45%',
                                                      left: '45%'
                                                    }}
                                                    onClick={() => {
                                                      openImage(imagePayload)
                                                    }}
                                                  >
                                                    <MaximizeIcon className="size-6 text-grey-100" />
                                                  </div>
                                                )}
                                              </div>
                                            </Box>
                                          }
                                          fallback={
                                            <>
                                              <Box
                                                sx={{
                                                  display: 'flex',
                                                  flexDirection: 'column',
                                                  justifyContent: 'center',
                                                  wordBreak: 'break-word'
                                                }}
                                              >
                                                <Box
                                                  sx={{
                                                    fontWeight: '600',
                                                    fontSize: '16px',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    '-webkit-line-clamp': 2,
                                                    display: '-webkit-box',
                                                    '-webkit-box-orient':
                                                      'vertical'
                                                  }}
                                                >
                                                  {displayName}
                                                </Box>
                                              </Box>
                                            </>
                                          }
                                        />
                                      </Box>
                                    </Box>
                                  </Box>
                                  <div
                                    className="flex items-center justify-between p-3 bg-grey-100"
                                    style={{
                                      ...(contentSearched && {
                                        width: 'calc(100% - 160px)'
                                      })
                                    }}
                                  >
                                    {proposal_name ? (
                                      <Tooltip title={proposal_name}>
                                        <p
                                          className="m-0 text-xs font-medium cursor-pointer"
                                          style={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            height: '100%'
                                          }}
                                          onClick={() => {
                                            searchQuery === ''
                                              ? openPdf(
                                                  proposal_id,
                                                  page_number
                                                )
                                              : openResumeFileDialog(
                                                  proposal_id,
                                                  page_number
                                                )
                                          }}
                                        >
                                          {contentSearched ? (
                                            <div className="flex flex-col h-full align-start">
                                              <div className="flex items-center gap-1 mb-2 text-sm text-grey-800">
                                                {proposal_name}
                                                {page_number && (
                                                  <span className="ml-2 font-medium">
                                                    - Page {page_number}
                                                  </span>
                                                )}{' '}
                                              </div>
                                              <TextLineLimiter
                                                content={
                                                  <span
                                                    className="text-grey-500"
                                                    style={{
                                                      whiteSpace: 'pre-wrap'
                                                    }}
                                                    dangerouslySetInnerHTML={{
                                                      __html: searchedContent
                                                    }}
                                                  />
                                                }
                                                limit={2}
                                                startAt="<mark>"
                                                isStartAtHTML="mark"
                                                styleProps={{
                                                  whiteSpace: 'pre-wrap'
                                                }}
                                              />
                                            </div>
                                          ) : (
                                            <div className="text-grey-500">
                                              {proposal_name}
                                            </div>
                                          )}
                                        </p>
                                      </Tooltip>
                                    ) : (
                                      <p className="m-0 text-sm font-medium">
                                        Untitled Project
                                      </p>
                                    )}

                                    <div className="relative flex flex-col justify-between w-16 h-full card-options">
                                      <div className="flex justify-end rounded text-grey-700">
                                        <span
                                          onClick={(e) =>
                                            handleClick(e, resume)
                                          }
                                          className="grid cursor-pointer place-content-center"
                                        >
                                          <ThreeDotsIcon
                                            className="size-5"
                                            strokeWidth={2}
                                          />
                                        </span>
                                        <Menu
                                          elevation={2}
                                          TransitionComponent={
                                            Grow
                                            // style={{
                                            //   // transformOrigin from center
                                            //   transformOrigin: '10px 10px'
                                            // }}
                                          }
                                          id="basic-menu"
                                          anchorEl={anchorEl}
                                          open={open}
                                          onClose={handleCloseMenu}
                                          MenuListProps={{
                                            'aria-labelledby': 'basic-button'
                                          }}
                                          className="p-1"
                                        >
                                          <Button2
                                            noOutline
                                            secondary
                                            style={{
                                              width: '100%'
                                            }}
                                            onClick={() =>
                                              handleDownloadCustomPdf(
                                                selectedResume.proposal_id,
                                                selectedResume.page_number - 1,
                                                coverImage
                                              )
                                            }
                                          >
                                            Download Resume
                                          </Button2>
                                          {!isUserViewer && (
                                            <Button2
                                              noOutline
                                              secondary
                                              style={{
                                                width: '100%'
                                              }}
                                              onClick={() =>
                                                handleMove(selectedResume)
                                              }
                                            >
                                              Move Resume
                                            </Button2>
                                          )}
                                          {!isUserViewer && (
                                            <Button2
                                              noOutline
                                              secondary
                                              style={{
                                                width: '100%'
                                              }}
                                              onClick={() => {
                                                console.log(
                                                  'resume',
                                                  filteredResumes,
                                                  resume
                                                )
                                                handleMasterMappingDelete(
                                                  people,
                                                  index,
                                                  'delete',
                                                  selectedResume.id
                                                )
                                              }}
                                            >
                                              Remove Resume
                                            </Button2>
                                          )}
                                        </Menu>
                                      </div>
                                      {contentSearched && (
                                        <div className="flex justify-end mt-2">
                                          <Tooltip title="Copy">
                                            <CopyIcon
                                              className="cursor-pointer size-3"
                                              onClick={() => {
                                                handleCopy(searchedContent)
                                              }}
                                            />
                                          </Tooltip>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </Box>
                              </div>
                            )
                          )
                        })}
                    </div>
                  </div>
                )}
              </div>
            )}

            {activeTab === 1 && (
              <Notebook
                notesData={notes}
                refresh={() => {
                  refreshData()
                }}
                source={{
                  id: people?.id,
                  type: 'resume',
                  name: people?.name
                }}
              />
            )}
            {activeTab === 2 && (
              <AttachFilesUI
                loading={loadingData}
                files={files}
                setFiles={setFiles}
                profileId={people.id}
                source="people"
                isUserViewer={isUserViewer}
              />
            )}
          </div>
        </div>

        <Drawer
          anchor={'right'}
          open={showTagDrawer}
          onClose={() => {
            setShowTagDrawer(false)
          }}
          disableEnforceFocus={true}
        >
          <Box sx={{ width: '400px', height: '100%' }}>
            <TagCenterUpdate
              id={people.id}
              filterTags="people"
              document_type={'masterresume'}
              onUpdateCallback={() => {
                refreshData()
              }}
              cancelCallback={() => {
                setShowTagDrawer(false)
              }}
            />
          </Box>
        </Drawer>

        {editProfile && (
          <EditProfileModal
            open={editProfile}
            people={people}
            setOpen={setEditProfile}
            onUpdate={() => refresh()}
          />
        )}

        <Dialog
          fullScreen
          sx={{ margin: '5vh' }}
          disableEnforceFocus={true}
          open={openResumeFileViewer}
          onClose={onResumeFileDialogClose}
          PaperProps={{
            style: {
              backgroundColor: 'transparent',
              boxShadow: 'none'
            }
          }}
        >
          <Box className={classes.fileViwerWrapper}>
            <Box sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
              <ResourceFileView
                s3Obj={s3Obj}
                searchKey={searchQuery}
                file={selectedResumeFile}
                onClose={onResumeFileDialogClose}
                onDeleteCallback={(e) => {
                  console.log(e)
                }}
                isModelOpen={openResumeFileViewer}
                onUpdateCallback={(e) => {
                  console.log(e)
                }}
              />
            </Box>
          </Box>
        </Dialog>
      </div>
    </div>
  )
}

export default ResumeGrid
