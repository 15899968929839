import {
  AddCalendarIcon,
  CalendarIcon,
  CircleCheckIcon,
  LocationIcon,
  PainPointsScroll,
  PencilWriteIcon,
  ScopeIcon
} from '@/components/Icons/Icons'
import { getColorForIndex } from '@/utils/String'
import moment, { utc } from 'moment'
import React, { useEffect, useState } from 'react'
import { Activity } from './Activity'
import { ModernTooltip } from '@/components/ModernTooltip/ModernTooltip'
import GoogleCalendarIcon from '@/assets/svg/google-calendar.svg'
import OutlookCalendarIcon from '@/assets/svg/outlook-calendar.svg'
import CommentUI from '../CommentUI/CommentUI'
import AttachFilesUI from '@/components/AttachFilesUI/AttachFilesUI'
import AttachFilesDropdown from '@/components/AttachFilesDropdown/AttachFilesDropdown'
import { changeProjectInfo, getParticularProject } from '@/store/api'
import trackEvent from '@/utils/TrackEvent/TrackEvent'
import mixpanelEvents from '@/config/mixpanelEvents'
const Overview = ({
  data,
  id,
  commentsData = [],
  refreshOpportunityState = false,
  uploadedFiles = [],
  setRefreshOpportunityState = () => {},
  attachRFP = () => {}
}) => {
  const [renderData, setRenderData] = useState(data)
  const [rfxInProgress, setRfxInProgress] = useState(false)
  const [rfxFile, setRfxFile] = useState(null)

  useEffect(() => {
    uploadedFiles.map((file) => {
      if (file.document_type === 'rfx' && file.status !== 'completed') {
        if (!rfxInProgress && !renderData) {
          setRfxInProgress(true)
        } else {
          setRfxInProgress(false)
        }
      }
      return null
    })
  }, [renderData])

  useEffect(() => {
    const interval = setInterval(() => {
      if (rfxInProgress) {
        refresh()
      } else {
        clearInterval(interval)
      }
    }, 10000)

    return () => clearInterval(interval)
  }, [rfxInProgress])

  const refresh = async () => {
    const res = await getParticularProject(id, true)
    setRenderData(res?.data?.project?.rfx_analysis?.[0] ?? null)
    setRfxInProgress(false)
  }

  const addToCalendar = (date, calendar = 'google') => {
    // https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20250220T061500Z%2F20250220T061500Z&details=&location=&text=Hello%20New%20Job

    if (calendar === 'google') {
      const startTime = moment(utc(date.startDateTime)).format(
        'YYYYMMDDTHHMM00Z'
      )
      window.open(
        `https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${startTime}/${startTime}&details=&location=${date.location}&text=${date.name}`,
        '_blank'
      )
    }

    // https://outlook.live.com/calendar/0/action/compose?allday=false&body=&enddt=2025-02-20T11%3A45%3A00&location=&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=2025-02-20T11%3A45%3A00&subject=Hello%20New%20Job

    if (calendar === 'outlook') {
      const startTime = moment(utc(date.startDateTime)).format(
        'YYYY-MM-DDTHH:mm:00Z'
      )
      const endTime = moment(utc(date.startDateTime)).format(
        'YYYY-MM-DDTHH:mm:00Z'
      )

      window.open(
        `https://outlook.live.com/calendar/0/action/compose?allday=false&body=&enddt=${endTime}&location=${date.location}&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=${startTime}&subject=${date.name}`,
        '_blank'
      )
    }

    trackEvent(
      mixpanelEvents?.OPPORTUNITY_ADD_CALENDAR_EVENT,
      SUCCESS,
      {},
      {
        calendar
      }
    )
  }

  return (
    <div className="pt-2 twp font-poppins">
      {renderData ? (
        <>
          <div className="px-3">
            {/* <div
          className="flex p-3 pb-3 mb-2 overflow-y-auto rounded-md bg-zinc-50 scrollbar2 "
        >
          {keyDates?.map((date, index) => (
            <div
              key={date.name}
              className="flex flex-col items-center gap-3 text-center last:border-r-0 "
            >
              <div
                className="p-1.5 py-3 w-48 border-b border-zinc-200 relative"
                //   style={{
                //     borderColor: getColorForIndex(index)
                //   }}
              >
                <p className="flex items-center justify-center gap-1 text-xs font-medium text-zinc-500">
                  <span className="">
                    {moment(utc(date.startDateTime)).format('D')}
                  </span>
                  {moment(utc(date.startDateTime)).format('MMM')}

                  <div
                    className="size-2 rounded-full absolute -bottom-[5px] left-1/2"
                    style={{
                      backgroundColor: getColorForIndex(index)
                    }}
                  ></div>
                </p>
              </div>
              <p className="font-medium text-xxs ">{date.name}</p>
            </div>
          ))}
        </div> */}
          </div>
          <div className="grid w-full grid-cols-2 gap-3 p-3 px-1">
            <div className="flex flex-col gap-4">
              {renderData?.project_summary && (
                <div
                  className="p-3 px-4 bg-white border rounded-md border-zinc-100"
                  tabIndex={0}
                  autoFocus
                >
                  <p className="flex items-center gap-2 text-base font-medium">
                    <div className="p-2 rounded-md bg-sky-50 text-sky-800">
                      <PencilWriteIcon className="size-4" />
                    </div>
                    Project Summary
                  </p>
                  <p className="text-xs mt-1.5 leading-[22px]">
                    {renderData?.project_summary}
                  </p>
                </div>
              )}

              {renderData?.project_scope && (
                <div className="h-full p-3 px-4 bg-white border rounded-md border-zinc-100">
                  <p className="flex items-center gap-2 text-base font-medium">
                    <div className="p-2 text-purple-800 rounded-md bg-purple-50">
                      <ScopeIcon className="size-4" />
                    </div>
                    Project Scope
                  </p>
                  <p className="text-xs mt-1.5 leading-[22px]">
                    {renderData?.project_scope}
                  </p>
                </div>
              )}
            </div>
            <div className="flex flex-col gap-6">
              <div className="h-full p-3 px-4 bg-white border rounded-md border-zinc-100">
                <p className="flex items-center gap-2 pl-2 mb-1 text-sm font-medium">
                  <div className="p-2 text-green-800 rounded-md bg-green-50">
                    <CalendarIcon className="size-4" />
                  </div>
                  Timeline
                </p>

                <div className="flex flex-col gap-3 p-2 px-3 mt-3 overflow-y-auto rounded-md border-zinc-100 bg-zinc-50">
                  {renderData?.key_dates?.map((date, index) => (
                    <div
                      key={date.name}
                      className="flex items-center gap-4 border-zinc-100 group"
                    >
                      <div
                        className="py-3 w-14 border-r-[1.5px] relative"
                        style={{
                          borderColor: getColorForIndex(index)
                        }}
                      >
                        {/* <div className="size-2 rounded-full bg-white border border-zinc-200 absolute top-1/2 -translate-y-1/2 -right-[8.3px] -translate-x-1/2"></div> */}
                        <p className="font-medium text-xxs text-zinc-500">
                          {moment(utc(date.startDateTime)).format('D, MMM')}
                        </p>
                      </div>
                      <div className="w-[60%]">
                        <p className="font-medium text-xxs">{date.name}</p>
                      </div>
                      <ModernTooltip
                        clickable
                        delayDuration={0}
                        content={
                          <div className="w-64 bg-white border border-zinc-100 text-zinc-700">
                            <p className="mt-2 text-xs font-medium">
                              {date.name}
                            </p>
                            <p className="flex items-center gap-1 mt-1 text-xxs">
                              <LocationIcon className="size-3" />
                              {date.location}
                            </p>

                            <div className="flex items-center gap-2 mt-3">
                              <p className="text-xs">Add to calendar</p>

                              <div
                                onClick={() => addToCalendar(date, 'google')}
                              >
                                <img
                                  src={GoogleCalendarIcon}
                                  alt="google-calendar"
                                  className="size-6"
                                />
                              </div>

                              <div
                                onClick={() => addToCalendar(date, 'outlook')}
                              >
                                <img
                                  src={OutlookCalendarIcon}
                                  alt="outlook-calendar"
                                  className="size-4"
                                />
                              </div>
                            </div>
                          </div>
                        }
                      >
                        <div className="ml-auto mr-2">
                          <AddCalendarIcon className="size-3 text-zinc-500" />
                        </div>
                      </ModernTooltip>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="grid h-40 mt-3 text-sm border rounded-md place-content-center border-zinc-100 text-zinc-500">
          {rfxInProgress ? (
            <div className="flex flex-col items-center gap-2 text-sm font-medium text-orange-500">
              <div className="flex flex-col items-center gap-2">
                <div className="bg-orange-500 rounded-full size-2 animate-ping"></div>
                <p>RFx is in progress</p>
              </div>
              <p className="text-xs font-normal text-orange-500">
                Please check back later
              </p>
            </div>
          ) : (
            <>
              No RFx Details Found
              <div className="mx-auto mt-3 w-fit">
                <AttachFilesDropdown
                  source={{ id, type: 'opportunity' }}
                  placeholder="Attach RFP/Q"
                  filterType="rfx"
                  onSelect={(file) => {
                    setRfxFile(file)
                    attachRFP(file)
                  }}
                  value={rfxFile}
                />
              </div>
            </>
          )}
        </div>
      )}
      <div className="p-3 space-y-3">
        <Activity
          id={id}
          refreshOpportunityState={refreshOpportunityState}
          setRefreshOpportunityState={setRefreshOpportunityState}
        />
        <CommentUI
          commentsData={commentsData}
          source={{ id, type: 'opportunity' }}
        />
      </div>
    </div>
  )
}

export default Overview
