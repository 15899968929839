import Button2 from '@/components/Button/Button2'
import {
  ChevronRightIcon,
  PeopleIcon2,
  ProjectIcon
} from '@/components/Icons/Icons'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import PeopleSelector from '@/pages/Workspace/Matrix/PeopleSelector'
import ProjectSelector from '@/pages/Workspace/Matrix/ProjectSelector'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import GridMatrix from './GridMatrix'
import { getMatrixData, updateMatrixData } from '@/store/api'

const PeopleProjectMatrix = ({ workspaceId }) => {
  const [peopleMatrixData, setPeopleMatrixData] = useState([])
  const [projectMatrixData, setProjectMatrixData] = useState([])
  const [showGridMatrix, setShowGridMatrix] = useState(false)
  const [relationData, setRelationData] = useState({})

  useEffect(() => {
    initializeMatrixData()
  }, [])

  const initializeMatrixData = async () => {
    const response = await getMatrixData(workspaceId)
    if (response.status === 200) {
      const peoples = response.data.people_ids_order_names.map((person) => {
        return {
          id: person.people_id,
          name: person.people_name,
          profile_picture: person.profile_picture
        }
      })

      const projects = response.data.project_ids_order_names.map((project) => {
        return {
          id: project.project_id,
          title: project.project_name
        }
      })

      setPeopleMatrixData(peoples)
      setProjectMatrixData(projects)
      setRelationData(response.data.relationship_data)
      setShowGridMatrix(true)
    }

    if (response.status === 404) {
      setPeopleMatrixData([])
      setProjectMatrixData([])
      setShowGridMatrix(false)
    }
  }

  const saveMatrixData = async () => {
    const relationshipData = {}
    const projectIdsOrder = []
    const peopleIdsOrder = []
    projectMatrixData.forEach((project) => {
      relationshipData[project.id] = []
      projectIdsOrder.push(project.id)
    })

    peopleMatrixData.forEach((person) => {
      peopleIdsOrder.push(person.id)
    })

    const data = {
      workspace_id: workspaceId,
      relationship_data: relationshipData,
      project_ids_order: projectIdsOrder,
      people_ids_order: peopleIdsOrder
    }
    const response = await updateMatrixData(data)
    console.log('response', response)

    if (response.status === 200) {
      toast.success('Matrix data saved successfully')
      setShowGridMatrix(true)
    } else {
      toast.error('Failed to save matrix data')
    }
  }

  return (
    <div
      className="overflow-hidden"
      style={{
        height: 'calc(100vh - 100px)'
      }}
    >
      <div className="relative flex items-center justify-between border-b twp border-zinc-100">
        {showGridMatrix ? (
          <GridMatrix
            workspaceId={workspaceId}
            peopleMatrixData={peopleMatrixData}
            projectMatrixData={projectMatrixData}
            relationData={relationData}
            goBack={() => setShowGridMatrix(false)}
          />
        ) : (
          <div className="relative flex items-center w-full gap-2 px-1">
            <Tabs defaultValue="projects" className="w-full p-2">
              <TabsList>
                <TabsTrigger value="projects">
                  <ProjectIcon className="mr-2 size-4" />
                  Projects
                </TabsTrigger>
                <TabsTrigger value="people">
                  <PeopleIcon2 className="mr-2 size-4" />
                  People
                </TabsTrigger>
              </TabsList>
              <TabsContent value="projects">
                <ProjectSelector
                  projectMatrixData={projectMatrixData}
                  setProjectMatrixData={setProjectMatrixData}
                />
              </TabsContent>
              <TabsContent value="people">
                <PeopleSelector
                  peopleMatrixData={peopleMatrixData}
                  setPeopleMatrixData={setPeopleMatrixData}
                />
              </TabsContent>
            </Tabs>

            <div
              className="absolute top-3 right-3 disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={true}
            >
              <div className="flex items-center gap-2">
                <div className="text-sm font-medium ">
                  {projectMatrixData.length === 0 &&
                  peopleMatrixData.length === 0 ? (
                    <p className="px-4 py-1 text-xs font-medium border rounded-full text-zinc-700 border-zinc-200">
                      Start selecting projects and people to continue
                    </p>
                  ) : (
                    <div className="flex items-center text-sm font-medium border rounded-full border-zinc-200">
                      <div className="px-4 py-1 rounded-l-full bg-zinc-100">
                        Selected
                      </div>
                      <div className="flex gap-2 px-3 bg-white rounded-r-full">
                        <p className="flex items-center gap-1">
                          {projectMatrixData.length} Projects
                        </p>
                        -
                        <p className="flex items-center gap-1">
                          {peopleMatrixData.length} People
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <Button2
                  primary
                  onClick={() => {
                    saveMatrixData()
                  }}
                  disabled={
                    projectMatrixData.length === 0 ||
                    peopleMatrixData.length === 0
                  }
                >
                  Select & Continue
                  <ChevronRightIcon className="size-3" />
                </Button2>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default PeopleProjectMatrix
